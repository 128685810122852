
html {
  scroll-behavior:smooth;
}

body {
  overflow-x:hidden;
background:rgb(0, 0, 0);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.box{
  width:10%;
  position:absolute;
  top:57vh;
  left:43.5vw;

}

.ZE{
  position:absolute;
  top:41vh;
  left:43vw;
  height:170px;
  width:210px;
  z-index:1;
  opacity:1
}

.button-85 {
  height:55px;
  width:230px;
  
  border: none;
  outline: none;
  color: rgb(255, 0, 187);
  background: transparent;
  cursor: pointer;
  top:1288vh;
  left:13.5vw;
  z-index: 5;
  border-radius: 5px;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  font-family:Aktura;
  font-size:25px;
  font-weight:100;
  padding-bottom:3px;
  transition:0.5s;

}

.button-85:before {
  content: "";
  background: linear-gradient(
    45deg,
    red,
    orangered,
    purple,
    rgb(255, 0, 119),
    rgb(163, 26, 79),
    rgb(161, 16, 245),
    blue,
    rgb(132, 0, 255),
    red
  );
  position: absolute;
  top: -2px;
  left: -2px;
  background-size: 400%;
  z-index: -1;
  filter: blur(5px);
  -webkit-filter: blur(5px);
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  animation: glowing-button-85 20s linear infinite;
  transition: opacity 0.3s ease-in-out;
  border-radius: 10px;
}

@keyframes glowing-button-85 {
  0% {
    background-position: 0 0;
  }
  50% {
    background-position: 400% 0;
  }
  100% {
    background-position: 0 0;
  }
}

.button-85:after {
  z-index: -1;
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: black;
  left: 0;
  top: 0;
  border-radius: 15px;
}

.button-85:hover{
  background:red;
  transition:0.5s;
  color:darkred,
  
}




.button-851 {
width:70px;
height:70px;
  border: none;
  outline: none;
  background: transparent;
  cursor: pointer;
  color:white;
  z-index: 5;
  border-radius: 100%;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  font-family:Dancing Script;
  padding-bottom:23px;
  transition:0.5s;
  position:absolute;
  left:53vw;
  top:5vh;
  background:red;

}

.button-851:before {
  content: "";
  background: linear-gradient(
    45deg,
    red,
    blue,
    darkblue,
    purple,
    #002bff,
    #002bff,
    #7a00ff,
    purple,
    blue
  );
  position: absolute;
  top: -2px;
  left: -2px;
  background-size: 400%;
  z-index: -1;
  filter: blur(2px);
  -webkit-filter: blur(5px);
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  animation: glowing-button-851 20s linear infinite;
  transition: opacity 0.3s ease-in-out;
  border-radius: 100%;
}

@keyframes glowing-button-851 {
  0% {
    background-position: 0 0;
  }
  50% {
    background-position: 400% 0;
  }
  100% {
    background-position: 0 0;
  }
}

.button-851:after {
  z-index: -1;
  content: "";
  position: absolute;
  background: transparent;
  left: 0;
  top: 0;
  border-radius: 100px;
}

.button-851:hover{
  background:white;
  transition:0.5s;
  
}













.Professional{
  position:fixed;
  top:7vh;
  left:7vw;
  z-index:2;
  height:220px;
  z-index:25;

}
.Wheel{
  position:fixed;
  top:11vh;
  left:8.5vw;
  z-index:0;
  height:152px;
  width:192px;
  z-index:24;
}











@-webkit-keyframes ani-mouse {
	0% {
	opacity: 1;
	top: 29%;
	}
	15% {
	opacity: 1;
	top: 50%;
	}
	50% {
	opacity: 0;
	top: 50%;
	}
	100% {
	opacity: 0;
	top: 29%;
	}
}
@-moz-keyframes ani-mouse {
	0% {
	opacity: 1;
	top: 29%;
	}
	15% {
	opacity: 1;
	top: 50%;
	}
	50% {
	opacity: 0;
	top: 50%;
	}
	100% {
	opacity: 0;
	top: 29%;
	}
}
@keyframes ani-mouse {
	0% {
	opacity: 1;
	top: 29%;
	}
	15% {
	opacity: 1;
	top: 50%;
	}
	50% {
	opacity: 0;
	top: 50%;
	}
	100% {
	opacity: 0;
	top: 29%;
	}
}
 .scroll-btn {
	position: absolute;
top:83vh;
left:46.6vw;
	text-align: center;
}
.scroll-btn > * {
	display: inline-block;
	line-height: 18px;
	font-size: 13px;
	font-weight: normal;
	color: #7f8c8d;
	color: #ffffff;
	font-family: "proxima-nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
	letter-spacing: 2px;
}
.scroll-btn > *:hover,
.scroll-btn > *:focus,
.scroll-btn > *.active {
	color: #ffffff;
}
.scroll-btn > *:hover,
.scroll-btn > *:focus,
.scroll-btn > *:active,
.scroll-btn > *.active {
	opacity: 0.8;
	filter: alpha(opacity=80);
}
.scroll-btn .mouse {
	position: relative;
	display: block;
	width: 35px;
	height: 55px;
	margin: 0 auto 20px;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	border: 3px solid rgb(255, 255, 255);
	border-radius: 23px;
}
.scroll-btn .mouse > * {
	position: absolute;
	display: block;
	top: 29%;
	left: 50%;
	width: 8px;
	height: 8px;
	margin: -4px 0 0 -4px;
	background: rgb(254, 0, 127);
	border-radius: 50%;
	-webkit-animation: ani-mouse 2.5s linear infinite;
	-moz-animation: ani-mouse 2.5s linear infinite;
	animation: ani-mouse 2.5s linear infinite;
}



.Rss{
 top:10vh;right:18.5vw
}

.meat{
top:7vh;left:18vw
}

.jely{
  top:45.7vh; left:21vw; height:260px
}

.dait{
  top:179vh; right:18.5vw; 
}
.wordst{
  top:195.6vh; left:18.5vw;font-size:43px;width:700px
}
.words33{
  top:234.7vh; left:20.3vw;
}
.cha{
  top:150.6vh; right:26.6vw
}
.cha1{
  top:211.6vh; right:-2.3vw
}

.right{
  top:421vh;left:21vw;font-size:132px;
}
.Fem{
  top:155.3vh;left:12.4vw
}
.Me{
  top:155vh;left:5.4vw;
}
.dayatona{
  top:169vh;right:-24vw;height:600px; width:600px;scale:1
}

.vidt{
  top:1077vh;left:0vw; height:100vh;width:100vw;object-fit: fill;border-radius:4px;scale:0.7
}
.Sauvage{
  top:407vh;left:25vw;
}
.backt{
  background-color:rgb(4, 4, 4);
}

.toptt{
  position:absolute;
  top:225vh;
  right:-22vw;
  z-index:5;
font-size:350px;
-webkit-text-stroke: 0.4px rgb(66, 1, 127);
-webkit-text-fill-color: transparent;
font-family:Montserrat;
}
.topttt{
  position:absolute;
  top:265vh;
  left:-47vw;
  z-index:5;
font-size:350px;
-webkit-text-stroke: 0.6px rgb(10, 0, 89);
-webkit-text-fill-color: transparent;
font-family:Montserrat;
}

.topt{
  position:absolute;
  top:125vh;
  right:-27vw;
  z-index:5;
font-size:350px;
-webkit-text-stroke: 0.6px rgb(24, 13, 34);
-webkit-text-fill-color: transparent;
font-family:Montserrat;

}
.tope{
  position:absolute;
  top:165vh;
  right:34vw;
  z-index:5;
font-size:350px;
-webkit-text-stroke: 0.8px rgb(24, 13, 34);
-webkit-text-fill-color: transparent;
font-family:Montserrat;

}
.topee{
  position:absolute;
  top:345vh;
  right:26vw;
  z-index:5;
font-size:350px;
-webkit-text-stroke: 0.8px rgb(49, 48, 53);
-webkit-text-fill-color: transparent;
font-family:Montserrat;

}
.vot{
  top:697vh;left:0vw;height:140vh;width:100vw;object-fit: fill;opacity:80%; 

}

.swy{
  top:733vh;left:23vw;font-size:140px; font-family:britney;color:black;z-index:7
}
.swyy{
  top:760vh;left:23vw;font-size:60px; font-family:britney;color:rgb(255, 255, 255);z-index:7;width:1200px;cursor:default;
}
.Emp{
  top:740vh;right:17vw;
 }

 .finger{
  height:45vh;width:110vw;object-fit: fill;top:890vh;right:-4vw;

 }
 .us{
  top:910vh;left:35vw;font-size: 170px;font-family:britney;color:rgb(140, 140, 140);opacity:0;cursor:default;
 }
 .use{
  top:940vh;left:32vw;font-size: 170px;font-family:Montserrat;color:rgb(87, 0, 52);opacity:0;cursor:default;
 }

 .war{
  top:1107vh;right:-70vw;
 }
 .Finish{
  top:1215vh;right:8vw;font-size: 120px;font-family:britney;color:white;width:1500px;z-index:6;
 }

 .Finishh{
  top:1255vh;left:13.2vw;font-size: 40px;font-family:britney;color:red;z-index:6;
  -webkit-text-stroke: 1.6px rgb(226, 0, 98);
-webkit-text-fill-color: rgb(0, 0, 0);
box-shadow: inset 0 0 0 0 black;  transition: color .3s ease-in-out, box-shadow .3s ease-in-out;

 }
 .Finishh:hover{
  box-shadow: inset 550px 0 0 0 rgba(248, 0, 0, 0.338);
  cursor:default;
  color: black;
 }

 .fin{
  top:1265vh;left:13.1vw;background:rgb(15, 15, 15);width:350px;height:85px;border-radius:30px;
 }

 .finn{
  top:1265vh;left:34.1vw;background:rgb(15, 15, 15);width:350px;height:85px;border-radius:30px;
 }



 .shadowBox {
  background-color: rgb(0, 0, 0);
  /* Fallback color */
  background-color: rgba(0, 0, 0, 0.2);
  /* Black w/opacity/see-through */}

.rainbow {
  top:1230vh;left:12vw;font-size: 120px;
  text-align: center;
  text-decoration: underline;
  font-family: monospace;
  letter-spacing: 5px;
  font-family:Aktura;
  overflow:visible
}
.rainbow_text_animated {
  background: linear-gradient(to right, red, darkred , rgba(229, 56, 56, 0.607), #ff3399, purple);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  animation: rainbow_animation 6s ease-in-out infinite;
  background-size: 400% 100%;
}

@keyframes rainbow_animation {
  0%,100% {
      background-position: 0 0;
  }

  50% {
      background-position: 100% 0;
  }
}
















.line{
  top:204.3vh;right:41.7vw;border-radius:20%;height:27px;width:27px;background:linear-gradient(black,black);border:solid 0.1px rgb(97, 2, 37);
}



.linee{
  top:199.3vh;right:42.4vw;height:350px;width:1px;background:linear-gradient(rgb(60, 0, 109),black);border-radius:50px;
}















.ape {
  box-shadow: inset 0 0 0 0 black;
  color: black;
  margin: 0 -.25rem;
  padding: 0 .25rem;
  transition: color .3s ease-in-out, box-shadow .3s ease-in-out;
}
.ape:hover {
  box-shadow: inset 550px 0 0 0 rgba(248, 0, 0, 0.338);
  cursor:default;
  color: black;
}










.fz1{
height:100vh;
width:8vw;
background:rgb(3, 0, 10);
position:fixed;
left:0vw;
top:0vh;  z-index:34;

}
.fz2{
  height:100vh;
  width:10vw;
  background:rgb(3, 0, 10);
  position:fixed;
left:8vw;
top:0vh;
z-index:34;
}
  .fz3{

    height:100vh;
    width:8vw;
    background:rgb(3, 0, 10);
    position:fixed;
    left:16vw;
    top:0vh;
    z-index:34    }
    .fz4{

      height:100vh;
      width:8vw;
      background:rgb(3, 0, 10);
      position:fixed;
      left:24vw;
      top:0vh;
      z-index:34      }
      .fz5{

        height:100vh;
        width:8vw;
        background:rgb(3, 0, 10);
        position:fixed;
        left:32vw;
        top:0vh;
        z-index:34        }
        .fz6{

          height:100vh;
          width:8vw;
          background:rgb(3, 0, 10);
          position:fixed;
          left:40vw;
          top:0vh;
          z-index:34          }
  

          .fz7{

            height:100vh;
            width:8vw;
            background:rgb(3, 0, 10);
            position:fixed;
            left:48vw;
            top:0vh;
            z-index:34         
             }

             

        .fz11{

          height:100vh;
          width:8vw;
          background:rgb(3, 0, 10);
          position:fixed;
          right:0vw;
          top:0vh; z-index:34
          }
          .fz22{

            height:100vh;
            width:10vw;
            background:rgb(3, 0, 10);
            position:fixed;
            right:8vw;
          top:0vh;
          z-index:34            }
            .fz33{

              height:100vh;
              width:8vw;
              background:rgb(3, 0, 10);
              position:fixed;
              right:16vw;
              top:0vh;
              z-index:34              }
              .fz44{

                height:100vh;
                width:8vw;
                background:rgb(3, 0, 10);
                position:fixed;
                right:24vw;
                top:0vh;
                z-index:34                }
                .fz55{

                  height:100vh;
                  width:8vw;
                  background:rgb(3, 0, 10);
                  position:fixed;
                  right:32vw;
                  top:0vh;
                  z-index:34                  }
                  .fz66{
                    height:100vh;
                    width:8vw;
                    background:rgb(3, 0, 10);
                    position:fixed;
                    right:40vw;
                    top:0vh;
                    z-index:34                    
                  }


                    .ZO{
                      top:37vh; left:43vw;z-index:35;
                    }








.name{
  top:1266vh;left:13.1vw;font-size:40px;width:400px
}
.namee{
  top:1276vh;left:13.1vw; font-size:40px;width:550px;
}
::placeholder{
  color:#4b0126;
}









/* Track */
::-webkit-scrollbar {
  width: 10px; /* Width of the scrollbar */
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: white; /* Color of the scrollbar handle */
  border-radius: 5px; /* Rounded corners of the scrollbar handle */
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(158, 0, 66); /* Color of the scrollbar handle on hover */
  transition:1;

}

.credi{
  text-decoration:none;
  position:absolute;
  font-size:80px;
  font-family:Montserrat;
  top:1450vh;
  left:42vw;
  color:white;
  z-index:50;
}

.credi:hover{
  color:rgb(215, 0, 89);
  cursor:pointer;
}

.sigma{
  top:48vh;left:38vw;height:200px;
}
.triangle{
  display:none;
    }
    .h{
      top:35vh;right:12.3vw;font-size:45px;
   }
  
   .ha{
    top:44.7vh; right:12.3vw;font-size:45px;
  }
  .hac{
    top:54vh;right:11.8vw;font-size:45px;
  }
  .at{
    top:175vh; left:19vw;font-size:76px;
  }
  .stz{
    top:228vh; left:17vw;
  }
  .chpain{
    top:447vh;right:-28vw;
  }
  .fa-brands.fa-instagram {
    top:50vh;left:12vw;font-size:32px
  }
  .fa-brands.fa-x-twitter {
    top:60vh;left:12vw;font-size:32px
  }










  @media screen and (min-width: 1610px) and (max-width: 1720px) {
  
    .fa-brands.fa-instagram {
      left:10vw;
    }
    .fa-brands.fa-x-twitter {
      left:10vw;
    }
    .button-85 {
      height:55px;
      width:230px;
      top:1282vh;
      left:13.5vw;}
      .name{
        top:1260vh;left:13.1vw;
      }
      .namee{
        top:1270vh;left:13.1vw;
      }
      .war{
        top:1101vh;right:-90vw;
       }
       .Finish{
        top:1215vh;right:-5vw;font-size: 100px;
       }
      
       .Finishh{
        top:1255vh;left:-33.2vw;font-size: 40px;}
      
    
    
      .vot{
        top:697vh;left:0vw;height:120vh;width:100vw;object-fit: fill;opacity:80%; 
      
      }
      
      .swy{
        top:732vh;left:17vw;font-size:140px; font-family:britney;color:black;z-index:7
      }
      .swyy{
        top:756vh;left:16vw;font-size:55px; font-family:britney;color:rgb(255, 255, 255);z-index:7;width:1100px;cursor:default;
      }
      .Emp{
        top:740vh;right:11vw;
       }
      
       .finger{
        height:45vh;width:110vw;object-fit: fill;top:890vh;right:-4vw;
      
       }
       .us{
        top:910vh;left:35vw;font-size: 170px;font-family:britney;color:rgb(140, 140, 140);opacity:0;cursor:default;
       }
       .use{
        top:940vh;left:32vw;font-size: 170px;font-family:Montserrat;color:rgb(87, 0, 52);opacity:0;cursor:default;
       }
       .Island{
        top:1220vh;left:3vw;
       }
    
    
    
       .Finishh:hover{
        box-shadow: inset 550px 0 0 0 rgba(248, 0, 0, 0.338);
        cursor:default;
        color: black;
       }
      
       .fin{
        top:1265vh;left:11.1vw;background:rgb(15, 15, 15);width:350px;height:85px;border-radius:30px;
       }
      
       .finn{
        top:1265vh;left:34.1vw;background:rgb(15, 15, 15);width:350px;height:85px;border-radius:30px;
       }
      
      
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
      
    
    
    
    
    
    
    
    
    
    
      .chpain{
        top:437vh;right:-28vw;
      }
    .line{
      top:194.3vh;right:38.7vw;border-radius:20%;height:27px;width:27px;background:linear-gradient(black,black);border:solid 0.1px rgb(97, 2, 37);
    }
    
    
    
    .linee{
      top:189.3vh;right:39.6vw;height:350px;width:1px;background:linear-gradient(rgb(60, 0, 109),black);border-radius:50px;
    }
    
      .stz{
        top:213vh; left:16.8vw;
      }
      .dayatona{
        top:163vh;right:-30vw;height:600px; width:600px;scale:0.98
      }
      .Fem{
        top:148.3vh;left:12.1vw
      }
      .Me{
        top:149vh;left:2vw;
      }
    
      .at{
        top:168vh; left:20vw;
      }
    .Professional{
      top:7vh;
      left:6vw;
      z-index:2;
      height:220px;
      z-index:25;
    
    }
    .Wheel{
      top:11vh;
      left:8vw;
      z-index:0;
      height:152px;
      width:192px;
      z-index:24;
    }
      .Sauvage{
        top:407vh;left:15vw;
      }
      .right{
        top:420vh;left:11vw
      }
    
      .vidt{
        top:1110vh;left:-1vw; 
      }
      .cha1{
        top:213.6vh; right:-2.3vw
      }
      .cha{
        top:150.6vh; right:25.4vw
      }
      .words33{
        top:220vh; left:20.8vw;
      }
      .wordst{
        top:186.6vh; left:19.5vw;font-size:34px;width:530px
      }
      .dait{
        top:179vh; right:14.5vw; 
      }

      .jely{
        top:45vh; left:14vw; height:280px
      }
    
      .meat{
    top:7vh;left:12vw
      }
      .Rss{
    top:11vh;right:14vw
      }

    .sigma{
      top:48vh;left:35.4vw;height:200px;
    }
    
  }    






















  @media screen and (min-width: 1560px) and (max-width: 1610px) {
  
    .fa-brands.fa-instagram {
      left:10vw;
    }
    .fa-brands.fa-x-twitter {
      left:10vw;
    }
    .button-85 {
      height:55px;
      width:230px;
      top:1282vh;
      left:13.5vw;}
      .name{
        top:1260vh;left:13.1vw;
      }
      .namee{
        top:1270vh;left:13.1vw;
      }
      .war{
        top:1107vh;right:-95vw;
       }
       .Finish{
        top:1215vh;right:-13vw;font-size: 100px;
       }
      
       .Finishh{
        top:1255vh;left:-33.2vw;font-size: 40px;}
      
    
    
      .vot{
        top:697vh;left:0vw;height:140vh;width:100vw;object-fit: fill;opacity:80%; 
      
      }
      
      .swy{
        top:732vh;left:17vw;font-size:140px; font-family:britney;color:black;z-index:7
      }
      .swyy{
        top:756vh;left:16vw;font-size:55px; font-family:britney;color:rgb(255, 255, 255);z-index:7;width:1100px;cursor:default;
      }
      .Emp{
        top:740vh;right:11vw;
       }
      
       .finger{
        height:45vh;width:110vw;object-fit: fill;top:890vh;right:-4vw;
      
       }
       .us{
        top:910vh;left:35vw;font-size: 170px;font-family:britney;color:rgb(140, 140, 140);opacity:0;cursor:default;
       }
       .use{
        top:940vh;left:32vw;font-size: 170px;font-family:Montserrat;color:rgb(87, 0, 52);opacity:0;cursor:default;
       }
       .Island{
        top:1220vh;left:3vw;
       }
    
    
    
       .Finishh:hover{
        box-shadow: inset 550px 0 0 0 rgba(248, 0, 0, 0.338);
        cursor:default;
        color: black;
       }
      
       .fin{
        top:1265vh;left:11.1vw;background:rgb(15, 15, 15);width:350px;height:85px;border-radius:30px;
       }
      
       .finn{
        top:1265vh;left:34.1vw;background:rgb(15, 15, 15);width:350px;height:85px;border-radius:30px;
       }
      
      
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
      
    
    
    
    
    
    
    
    
    
    
      .chpain{
        top:437vh;right:-28vw;
      }
    .line{
      top:194.3vh;right:38.7vw;border-radius:20%;height:27px;width:27px;background:linear-gradient(black,black);border:solid 0.1px rgb(97, 2, 37);
    }
    
    
    
    .linee{
      top:189.3vh;right:39.6vw;height:350px;width:1px;background:linear-gradient(rgb(60, 0, 109),black);border-radius:50px;
    }
    
      .stz{
        top:213vh; left:16.8vw;
      }
      .dayatona{
        top:163vh;right:-30vw;height:600px; width:600px;scale:0.98
      }
      .Fem{
        top:148.3vh;left:12.1vw
      }
      .Me{
        top:149vh;left:2vw;
      }
    
      .at{
        top:168vh; left:20vw;
      }
    .Professional{
      top:7vh;
      left:6vw;
      z-index:2;
      height:220px;
      z-index:25;
    
    }
    .Wheel{
      top:11vh;
      left:8vw;
      z-index:0;
      height:152px;
      width:192px;
      z-index:24;
    }
      .Sauvage{
        top:407vh;left:15vw;
      }
      .right{
        top:420vh;left:11vw
      }
    
      .vidt{
        top:1110vh;left:-1vw; 
      }
      .cha1{
        top:213.6vh; right:-2.3vw
      }
      .cha{
        top:150.6vh; right:25.4vw
      }
      .words33{
        top:220vh; left:20.8vw;
      }
      .wordst{
        top:186.6vh; left:19.5vw;font-size:34px;width:530px
      }
      .dait{
        top:179vh; right:14.5vw; 
      }

      .jely{
        top:45vh; left:14vw; height:280px
      }
    
      .meat{
    top:7vh;left:12vw
      }
      .Rss{
    top:11vh;right:12vw
      }

    .sigma{
      top:48vh;left:35.4vw;height:200px;
    }
    
  }    

















  
@media screen and (min-width: 1325px) and (max-width: 1524px) {
  
.button-85 {
  height:55px;
  width:230px;
  top:1282vh;
  left:13.5vw;}
  .name{
    top:1260vh;left:13.1vw;
  }
  .namee{
    top:1270vh;left:13.1vw;
  }
  .war{
    top:1107vh;right:-95vw;
   }
   .Finish{
    top:1215vh;right:-13vw;font-size: 100px;
   }
  
   .Finishh{
    top:1255vh;left:-33.2vw;font-size: 40px;}
  


  .vot{
    top:697vh;left:0vw;height:100vh;width:100vw;object-fit: fill;opacity:80%; 
  
  }
  
  .swy{
    top:732vh;left:17vw;font-size:140px; font-family:britney;color:black;z-index:7
  }
  .swyy{
    top:756vh;left:16vw;font-size:55px; font-family:britney;color:rgb(255, 255, 255);z-index:7;width:1100px;cursor:default;
  }
  .Emp{
    top:740vh;right:11vw;
   }
  
   .finger{
    height:45vh;width:110vw;object-fit: fill;top:890vh;right:-4vw;
  
   }
   .us{
    top:910vh;left:35vw;font-size: 170px;font-family:britney;color:rgb(140, 140, 140);opacity:0;cursor:default;
   }
   .use{
    top:940vh;left:32vw;font-size: 170px;font-family:Montserrat;color:rgb(87, 0, 52);opacity:0;cursor:default;
   }
   .Island{
    top:1220vh;left:3vw;
   }



   .Finishh:hover{
    box-shadow: inset 550px 0 0 0 rgba(248, 0, 0, 0.338);
    cursor:default;
    color: black;
   }
  
   .fin{
    top:1265vh;left:11.1vw;background:rgb(15, 15, 15);width:350px;height:85px;border-radius:30px;
   }
  
   .finn{
    top:1265vh;left:34.1vw;background:rgb(15, 15, 15);width:350px;height:85px;border-radius:30px;
   }
  
  




















  










  .chpain{
    top:437vh;right:-28vw;
  }
.line{
  top:194.3vh;right:38.7vw;border-radius:20%;height:27px;width:27px;background:linear-gradient(black,black);border:solid 0.1px rgb(97, 2, 37);
}



.linee{
  top:189.3vh;right:39.6vw;height:350px;width:1px;background:linear-gradient(rgb(60, 0, 109),black);border-radius:50px;
}

  .stz{
    top:213vh; left:16.8vw;
  }
  .dayatona{
    top:163vh;right:-30vw;height:600px; width:600px;scale:0.98
  }
  .Fem{
    top:148.3vh;left:12.1vw
  }
  .Me{
    top:149vh;left:2vw;
  }

  .at{
    top:168vh; left:20vw;
  }
.Professional{
  top:7vh;
  left:6vw;
  z-index:2;
  height:220px;
  z-index:25;

}
.Wheel{
  top:11vh;
  left:8vw;
  z-index:0;
  height:152px;
  width:192px;
  z-index:24;
}
  .Sauvage{
    top:407vh;left:15vw;
  }
  .right{
    top:420vh;left:11vw
  }

  .vidt{
    top:1110vh;left:-1vw; 
  }
  .cha1{
    top:213.6vh; right:-2.3vw
  }
  .cha{
    top:150.6vh; right:25.4vw
  }
  .words33{
    top:220vh; left:21.3vw;
  }
  .wordst{
    top:186.6vh; left:19.5vw;font-size:34px;width:530px
  }
  .dait{
    top:179vh; right:14.5vw; 
  }

  .jely{
    top:47vh; left:15vw; height:240px
  }

  .meat{
top:7vh;left:12vw
  }
  .Rss{
top:10vh;right:4.7vw
  }

.sigma{
  top:48vh;left:37.4vw;height:200px;
}


@media screen and (min-width: 1305px) and (max-width: 1400px) {
  .war{
    top:1107vh;right:-120vw;
   }
  .Finish{
    top:1220vh;right:-26vw;font-size: 90px;
   }
  
   .Finishh{
    top:1255vh;left:-33.2vw;font-size: 35px;}

    .Rss{
      top:10vh;right:2.7vw
        }

      .sigma{
        top:48vh;left:35.4vw;height:200px;
      }
      .jely{
        top:47vh; left:14vw; height:240px
      }
      .meat{
        top:10vh;left:2vw
          }


}




}

@media screen and (min-width: 1200px) and (max-width: 1324px) {
  .war{
    top:1110vh;right:-131vw;scale:1
   }
  .Finish{
    top:1220vh;right:-35vw;font-size: 95px;
   }
  
   .Finishh{
    top:1255vh;left:-33.2vw;font-size: 35px;}
  .us{
    top:910vh;left:30vw;font-size: 170px;font-family:britney;color:rgb(140, 140, 140);opacity:0;cursor:default;
   }
   .use{
    top:940vh;left:27vw;font-size: 170px;font-family:Montserrat;color:rgb(87, 0, 52);opacity:0;cursor:default;
   }
  .line{
    top:204.3vh;right:35.7vw;border-radius:20%;height:27px;width:27px;background:linear-gradient(black,black);border:solid 0.1px rgb(97, 2, 37);
  }
  
  
  
  .linee{
    top:199.3vh;right:36.6vw;height:350px;width:1px;background:linear-gradient(rgb(60, 0, 109),black);border-radius:50px;
  }
  
    .stz{
      top:221vh; left:15.8vw;
    }
    .dayatona{
      top:180vh;right:-39vw;height:600px; width:600px;scale:0.9
    }
    .Fem{
      top:155.3vh;left:10.1vw
    }
    .Me{
      top:155.4vh;left:-2.8vw;
    }
  .swy{
    top:729vh;left:17vw;font-size:140px; font-family:britney;color:black;z-index:7
  }
  .swyy{
    top:756vh;left:16vw;font-size:50px; font-family:britney;color:rgb(255, 255, 255);z-index:7;width:900px;cursor:default;
  }
  .Emp{
    top:734vh;right:4vw;
   }
  .h{
    top:35vh;right:3.3vw;
 }

 .ha{
  top:44.4vh; right:3.3vw;
}
.hac{
  top:54vh;right:2.3vw;
}
  .sigma{
    top:53vh;left:48.4vw;height:230px;
  }

  .jely{
    top:44vh; left:10vw; height:280px
  }

  .meat{
top:7vh;left:4vw
  }
  .Rss{
top:10vh;right:-5.7vw
  }


.sigma{
  top:48vh;left:37.4vw;height:220px;
}







  .chpain{
    top:437vh;right:-48vw;
  }





  .Sauvage{
    top:410vh;left:15vw;
  }
  .right{
    top:421vh;left:11vw
  }


  .vidt{
    top:1100vh;left:13vw; 
  }
  .cha1{
    top:213.6vh; right:-2.3vw
  }
  .cha{
    top:150.6vh; right:25.4vw
  }


  .dait{
    top:179vh; right:14.5vw; 
  }
  .line{
    top:184.3vh;right:37.7vw;border-radius:20%;height:27px;width:27px;background:linear-gradient(black,black);border:solid 0.1px rgb(97, 2, 37);
  }
  
  
  
  .linee{
    top:179.3vh;right:38.5vw;height:350px;width:1px;background:linear-gradient(rgb(60, 0, 109),black);border-radius:50px;
  }
  
    .stz{
      top:201vh; left:12.8vw;
    }
    .dayatona{
      top:160vh;right:-36vw;height:600px; width:600px;scale:0.92
    }
    .words33{
      top:208vh; left:18vw;
    }
    .wordst{
      top:175.3vh; left:16.7vw;font-size:32px;width:500px
    }

    .at{
      top:158vh; left:17vw;
    }
    .Fem{
      top:136.3vh;left:7.1vw
    }
    .Me{
      top:137.4vh;left:-4.7vw;
    }
}

@media screen and (min-width: 1250px) and (max-width: 1324px) {
  .Fem{
    top:146.3vh;left:12.1vw
  }
  .Me{
    top:146.4vh;left:-2vw;
  }

  .at{
    top:168vh; left:18vw;
  }
  .wordst{
    top:185.6vh; left:18.5vw;font-size:32px;width:500px
  }
  .words33{
    top:218vh; left:21.3vw;
  }
  .Rss{
    top:10vh;right:-1.7vw
      }

    .sigma{
      top:48vh;left:37vw;height:200px;
    }
    .jely{
      top:44vh; left:11vw; height:270px
    }

    .meat{
      top:10vh
}
.line{
  top:184.3vh;right:38.7vw;border-radius:20%;height:27px;width:27px;background:linear-gradient(black,black);border:solid 0.1px rgb(97, 2, 37);
}



.linee{
  top:179.3vh;right:39.5vw;height:350px;width:1px;background:linear-gradient(rgb(60, 0, 109),black);border-radius:50px;
}

  .stz{
    top:211vh; left:16.5vw;
  }
  .dayatona{
    top:160vh;right:-32vw;height:600px; width:600px;scale:0.95
  }
  .Professional{
    top:9vh;
    left:5vw;
    z-index:2;
    height:220px;
    z-index:25;
  
  }
  .Wheel{
    top:13vh;
    left:8vw;
    z-index:0;
    height:152px;
    width:192px;
    z-index:24;
  }
  .fa-brands.fa-instagram {
    left:11vw;
  }
  .fa-brands.fa-x-twitter {
    left:11vw;
  }

  .war{
    top:1110vh;right:-125vw;scale:1
   }
  .Finish{
    top:1220vh;right:-31vw;font-size: 95px;
   }
  
   .Finishh{
    top:1255vh;left:-25.2vw;font-size: 35px;}
}


@media screen and (min-width: 1460px) and (max-width: 1550px) {
  .Rss{
    top:10vh;right:4.7vw
      }

    .sigma{
      top:48vh;left:38.4vw;height:210px;
    }
    .jely{
      top:44vh; left:17.5vw; height:270px
    }

    .meat{
      top:10vh

}
}

@media screen and (min-width: 1380px) and (max-width: 1460px) {
  .Rss{
    top:10vh;right:5vw
      }

    .sigma{
      top:48vh;left:37vw;height:210px;
    }
    .jely{
      top:44vh; left:13.5vw; height:270px
    }

    .meat{
      top:10vh

}
.war{
  top:1116vh;right:-114vw;scale:0.95
 }
.Finish{
  top:1220vh;right:-20vw;font-size: 90px;
 }
 .Professional{
  top:9vh;
  left:6vw;
  z-index:2;
  height:220px;
  z-index:25;

}
.Wheel{
  top:13vh;
  left:8.3vw;
  z-index:0;
  height:152px;
  width:192px;
  z-index:24;
}
}

@media screen and (min-width: 1490px) and (max-width: 1560px) {
  .Rss{
    top:10vh;right:8vw
      }

    .sigma{
      top:48vh;left:38vw;height:210px;
    }
    .jely{
      top:44vh; left:15.5vw; height:280px
    }

    .meat{
      top:3vh

}
.war{
  top:1116vh;right:-110vw;scale:0.9
 }
.Finish{
  top:1220vh;right:-13vw;font-size: 90px;
 }
 .line{
  top:194.3vh;right:38.7vw;border-radius:20%;height:27px;width:27px;background:linear-gradient(black,black);border:solid 0.1px rgb(97, 2, 37);
}



.linee{
  top:189.3vh;right:39.5vw;height:350px;width:1px;background:linear-gradient(rgb(60, 0, 109),black);border-radius:50px;
}

  .stz{
    top:211vh; left:13.8vw;
  }
  .dayatona{
    top:166vh;right:-32vw;height:600px; width:600px;scale:0.94
  }
  .words33{
    top:218vh; left:18vw;
  }
  .wordst{
    top:185.3vh; left:16.7vw;font-size:34px;width:600px
  }
  .at{
    top:168vh; left:17vw;
  }
.Professional{
  top:9vh;
  left:6vw;
  z-index:2;
  height:220px;
  z-index:25;

}
.Wheel{
  top:13vh;
  left:8.3vw;
  z-index:0;
  height:152px;
  width:192px;
  z-index:24;
}
.Fem{
  top:148.3vh;left:9.4vw
}
.Me{
  top:148vh;left:0vw;
}

}




@media screen and (min-width: 1150px) and (max-width: 1200px) {

  .Fem{
    top:153.3vh;left:9vw
  }
  .Me{
    top:153.4vh;left:-4vw;
  }

  .at{
    top:173vh; left:18vw;
  }
  .wordst{
    top:190.6vh; left:18.5vw;font-size:32px;width:500px
  }
  .words33{
    top:223vh; left:21.3vw;
  }


.line{
  top:189.3vh;right:36.3vw;border-radius:20%;height:27px;width:27px;background:linear-gradient(black,black);border:solid 0.1px rgb(97, 2, 37);
}



.linee{
  top:185vh;right:37.5vw;height:350px;width:1px;background:linear-gradient(rgb(60, 0, 109),black);border-radius:50px;
}

  .stz{
    top:216vh; left:16vw;
  }
  .dayatona{
    top:170vh;right:-36vw;height:600px; width:600px;scale:0.92
  }
  .Professional{
    top:9vh;
    left:5vw;
    z-index:2;
    height:220px;
    z-index:25;
  
  }
  .Wheel{
    top:13vh;
    left:8vw;
    z-index:0;
    height:152px;
    width:192px;
    z-index:24;
  }
  .fa-brands.fa-instagram {
    left:11vw;
  }
  .fa-brands.fa-x-twitter {
    left:11vw;
  }


  .Rss{
    top:11vh;right:-3vw
      }

    .sigma{
      top:48vh;left:33vw;height:210px;
    }
    .jely{
      top:44vh; left:5.5vw; height:280px
    }



    .meat{
      top:7vh;
      left:3vw;
}


.h{
  top:35vh;right:4.3vw;
}

.ha{
top:44.7vh; right:4.3vw;
}
.hac{
top:54vh;right:3.3vw;
}

.chpain{
right:-58vw;
}
.swy{
  top:732vh;left:16vw;font-size:140px; font-family:britney;color:black;z-index:7
}
.swyy{
  top:756vh;left:16vw;font-size:55px; font-family:britney;color:rgb(255, 255, 255);z-index:7;width:900px;cursor:default;
}
.Emp{
  top:739vh;right:33vw;
 }
   .us{
    top:910vh;left:30vw;font-size: 170px;font-family:britney;color:rgb(140, 140, 140);opacity:0;cursor:default;
   }
   .use{
    top:940vh;left:27vw;font-size: 170px;font-family:Montserrat;color:rgb(87, 0, 52);opacity:0;cursor:default;
   }

   .war{
    top:1120vh;right:-145vw;scale:0.9
   }
  .Finish{
    top:1220vh;right:-40vw;font-size: 90px;
   }

  .us{
    top:910vh;left:30vw;font-size: 170px;font-family:britney;color:rgb(140, 140, 140);opacity:0;cursor:default;
   }
}

@media screen and (min-width: 1100px) and (max-width: 1150px) {



  .Fem{
    top:153.3vh;left:9vw
  }
  .Me{
    top:153.4vh;left:-4vw;
  }

  .at{
    top:173vh; left:18vw;
  }
  .wordst{
    top:190.6vh; left:18.5vw;font-size:32px;width:450px
  }



.line{
  top:189.3vh;right:35.3vw;border-radius:20%;height:27px;width:27px;background:linear-gradient(black,black);border:solid 0.1px rgb(97, 2, 37);
}



.linee{
  top:185vh;right:36.5vw;height:350px;width:1px;background:linear-gradient(rgb(60, 0, 109),black);border-radius:50px;
}


.words33{
  top:227vh; left:20.8vw;
}

  .stz{
    top:220vh; left:15vw;
  }
  .dayatona{
    top:174vh;right:-38vw;height:600px; width:600px;scale:0.9
  }
  .Professional{
    top:9vh;
    left:5vw;
    z-index:2;
    height:220px;
    z-index:25;
  
  }
  .Wheel{
    top:13vh;
    left:8vw;
    z-index:0;
    height:152px;
    width:192px;
    z-index:24;
  }
  .fa-brands.fa-instagram {
    left:11vw;
  }
  .fa-brands.fa-x-twitter {
    left:11vw;
  }


  .Rss{
    top:11vh;right:-9vw
      }
    .sigma{
      top:48vh;left:33vw;height:210px;
    }
    .jely{
      top:44vh; left:5.5vw; height:280px
    }



    .meat{
      top:7vh;
      left:3vw;
}


.h{
  top:35vh;right:4.3vw;
}

.ha{
top:44.7vh; right:4.3vw;
}
.hac{
top:54vh;right:3.3vw;
}

.chpain{
right:-58vw;
}
.swy{
  top:732vh;left:16vw;font-size:140px; font-family:britney;color:black;z-index:7
}
.swyy{
  top:756vh;left:16vw;font-size:55px; font-family:britney;color:rgb(255, 255, 255);z-index:7;width:900px;cursor:default;
}
.Emp{
  top:739vh;right:29vw;
 }
   .us{
    top:910vh;left:30vw;font-size: 170px;font-family:britney;color:rgb(140, 140, 140);opacity:0;cursor:default;
   }
   .use{
    top:940vh;left:27vw;font-size: 170px;font-family:Montserrat;color:rgb(87, 0, 52);opacity:0;cursor:default;
   }

   .war{
    top:1120vh;right:-155vw;scale:0.9
   }
  .Finish{
    top:1220vh;right:-45vw;font-size: 90px;
   }

  .us{
    top:910vh;left:30vw;font-size: 170px;font-family:britney;color:rgb(140, 140, 140);opacity:0;cursor:default;
   }



}


@media screen and (min-width: 1050px) and (max-width: 1100px) {
  .Fem{
    top:142vh;left:7vw
  }
  .Me{
    top:142.4vh;left:-6.3vw;
  }

  .at{
    top:163vh; left:18vw;
  }
  .wordst{
    top:180.6vh; left:18.5vw;font-size:32px;width:450px
  }



.line{
  top:179.3vh;right:35.3vw;border-radius:20%;height:27px;width:27px;background:linear-gradient(black,black);border:solid 0.1px rgb(97, 2, 37);
}



.linee{
  top:175vh;right:36.5vw;height:350px;width:1px;background:linear-gradient(rgb(60, 0, 109),black);border-radius:50px;
}


.words33{
  top:217vh; left:20.8vw;
}

  .stz{
    top:210vh; left:15vw;
  }
  .dayatona{
    top:164vh;right:-38vw;height:600px; width:600px;scale:0.9
  }
  .Professional{
    top:9vh;
    left:3vw;
    z-index:2;
    height:220px;
    z-index:25;
  
  }
  .Wheel{
    top:13vh;
    left:6vw;
    z-index:0;
    height:152px;
    width:192px;
    z-index:24;
  }
  .fa-brands.fa-instagram {
    left:11vw;
  }
  .fa-brands.fa-x-twitter {
    left:11vw;
  }


  .Rss{
    top:11vh;right:-11vw;scale:0.9
      }

    .sigma{
      top:48vh;left:32vw;height:210px;
    }
    .jely{
      top:44vh; left:5.5vw; height:280px
    }



    .meat{
      top:7vh;
      left:3vw;
}


.h{
  top:35vh;right:4.3vw;
}

.ha{
top:44.7vh; right:4.3vw;
}
.hac{
top:54vh;right:3.3vw;
}

.chpain{
right:-58vw;
}
.swy{
  top:733vh;left:16vw;font-size:120px; font-family:britney;color:black;z-index:7
}
.swyy{
  top:756vh;left:16vw;font-size:55px; font-family:britney;color:rgb(255, 255, 255);z-index:7;width:900px;cursor:default;
}
.Emp{
  top:739vh;right:29vw;
 }
   .us{
    top:910vh;left:30vw;font-size: 170px;font-family:britney;color:rgb(140, 140, 140);opacity:0;cursor:default;
   }
   .use{
    top:940vh;left:27vw;font-size: 170px;font-family:Montserrat;color:rgb(155, 150, 5);opacity:0;cursor:default;
   }

   .war{
    top:1120vh;right:-168vw;scale:0.89
   }
  .Finish{
    top:1220vh;right:-55vw;font-size: 80px;
   }

  .us{
    top:910vh;left:30vw;font-size: 170px;font-family:britney;color:rgb(140, 140, 140);opacity:0;cursor:default;
   }
   .name{
    top:1267vh;left:13.1vw;font-size:35px;width:300px
  }
  .namee{
    top:1277vh;left:13.1vw;
  }
}


@media screen and (min-width: 1000px) and (max-width: 1050px) {

  .Fem{
    top:149vh;left:6.8vw
  }
  .Me{
    top:148.4vh;left:-8vw;
  }

  .at{
    top:168vh; left:18vw;
  }
  .wordst{
    top:185.6vh; left:18.5vw;font-size:32px;width:450px
  }



.line{
  top:184.3vh;right:33.3vw;border-radius:20%;height:27px;width:27px;background:linear-gradient(black,black);border:solid 0.1px rgb(97, 2, 37);
}



.linee{
  top:180vh;right:34.5vw;height:350px;width:1px;background:linear-gradient(rgb(60, 0, 109),black);border-radius:50px;
}


.words33{
  top:222vh; left:20.2vw;
}

  .stz{
    top:215vh; left:14vw;
  }
  .dayatona{
    top:170vh;right:-40vw;height:600px; width:600px;scale:0.85
  }
  .Professional{
    top:9vh;
    left:3vw;
    z-index:2;
    height:220px;
    z-index:25;
  
  }
  .Wheel{
    top:13vh;
    left:6vw;
    z-index:0;
    height:152px;
    width:192px;
    z-index:24;
  }
  .fa-brands.fa-instagram {
    left:11vw;
  }
  .fa-brands.fa-x-twitter {
    left:11vw;
  }


  .Rss{
    top:11vh;right:-14vw;scale:0.9
      }

    .sigma{
      top:48vh;left:34vw;height:190px;
    }
    .jely{
      top:44vh; left:3.5vw; height:280px
    }


    .meat{
      top:7vh;
      left:3vw;
}


.h{
  top:35vh;right:4.3vw;
}

.ha{
top:44.7vh; right:4.3vw;
}
.hac{
top:54vh;right:3.3vw;
}

.chpain{
right:-58vw;
}
.swy{
  top:733vh;left:16vw;font-size:120px; font-family:britney;color:black;z-index:7
}
.swyy{
  top:756vh;left:16vw;font-size:45px; font-family:britney;color:rgb(255, 255, 255);z-index:7;width:800px;cursor:default;
}
.Emp{
  top:735vh;right:29vw;
 }
   .us{
    top:910vh;left:30vw;font-size: 170px;font-family:britney;color:rgb(140, 140, 140);opacity:0;cursor:default;
   }
   .use{
    top:940vh;left:27vw;font-size: 170px;font-family:Montserrat;color:rgb(87, 0, 52);opacity:0;cursor:default;
   }

   .war{
    top:1123vh;right:-182vw;scale:0.86
   }
  .Finish{
    top:1220vh;right:-61vw;font-size: 87px;
   }

  .us{
    top:910vh;left:30vw;font-size: 170px;font-family:britney;color:rgb(140, 140, 140);opacity:0;cursor:default;
   }

   .name{
    top:1267vh;left:13.1vw;font-size:35px;width:300px
  }
  .namee{
    top:1277vh;left:13.1vw;width:450px
  }
}

@media screen and (min-width: 950px) and (max-width: 1000px) {

  .Fem{
    top:144vh;left:6.8vw
  }
  .Me{
    top:143.4vh;left:-8vw;
  }

  .at{
    top:163vh; left:18vw;
  }
  .wordst{
    top:180.6vh; left:18.5vw;font-size:29px;width:400px
  }



.line{
  top:179.3vh;right:34.3vw;border-radius:20%;height:22px;width:22px;background:linear-gradient(black,black);border:solid 0.1px rgb(97, 2, 37);
}



.linee{
  top:175vh;right:35.5vw;height:350px;width:1px;background:linear-gradient(rgb(60, 0, 109),black);border-radius:50px;
}


.words33{
  top:217vh; left:20.2vw;
}

  .stz{
    top:210vh; left:13.5vw;
  }
  .dayatona{
    top:172vh;right:-40vw;height:600px; width:600px;scale:0.83
  }
  .Professional{
    top:9vh;
    left:-2vw;
    z-index:2;
    height:220px;
    z-index:25;scale:0.96
  
  }
  .Wheel{
    top:13vh;
    left:1vw;
    z-index:0;
    height:152px;
    width:192px;
    z-index:24;scale:0.98
  }
  .fa-brands.fa-instagram {
    left:6vw;
  }
  .fa-brands.fa-x-twitter {
    left:6vw;
  }


  .Rss{
    top:11vh;right:-17vw;scale:0.8
      }
    .sigma{
      top:48vh;left:34vw;height:170px;
    }
    .jely{
     display:none;
    }


    .meat{
      top:7vh;
      left:6vw;
      scale:0.95
}


.h{
  top:35vh;right:3.3vw;font-size:37px;
}

.ha{
top:44.7vh; right:3.3vw;font-size:37px;
}
.hac{
top:54vh;right:2.3vw;font-size:37px;
}

.chpain{
right:-63vw;
}
.swy{
  top:733vh;left:16vw;font-size:110px; font-family:britney;color:black;z-index:7
}
.swyy{
  top:756vh;left:16vw;font-size:40px; font-family:britney;color:rgb(255, 255, 255);z-index:7;width:740px;cursor:default;
}
.Emp{
  top:735vh;right:29vw;
 }

   .use{
    top:940vh;left:22vw;font-size: 160px;font-family:Montserrat;color:rgb(87, 0, 52);opacity:0;cursor:default;
   }

   .war{
    top:1123vh;right:-192vw;scale:0.84
   }
  .Finish{
    top:1222vh;right:-68vw;font-size: 77px;
   }
   .rainbow {
    top:1234vh;left:12vw;font-size: 90px;
  }
  .us{
    top:910vh;left:25vw;font-size: 160px;font-family:britney;color:rgb(140, 140, 140);opacity:0;cursor:default;
   }

   .name{
    top:1267vh;left:13.1vw;font-size:35px;width:300px
  }
  .namee{
    top:1277vh;left:13.1vw;width:450px
  }

}






@media screen and (min-width: 900px) and (max-width: 950px) {  

  .Fem{
    top:143vh;left:6.8vw
  }
  .Me{
    top:142.4vh;left:-11vw;
  }

  .at{
    top:163vh; left:18vw;
  }
  .wordst{
    top:180.6vh; left:18.5vw;font-size:29px;width:400px
  }



.line{
  top:179.3vh;right:34.3vw;border-radius:20%;height:22px;width:22px;background:linear-gradient(black,black);border:solid 0.1px rgb(97, 2, 37);
}



.linee{
  top:175vh;right:35.5vw;height:350px;width:1px;background:linear-gradient(rgb(60, 0, 109),black);border-radius:50px;
}


.words33{
  top:217vh; left:20.2vw;
}

  .stz{
    top:210vh; left:13.5vw;
  }
  .dayatona{
    top:172vh;right:-40vw;height:600px; width:600px;scale:0.83
  }
  .Professional{
    top:9vh;
    left:-2vw;
    z-index:2;
    height:220px;
    z-index:25;scale:0.96
  
  }

.Wheel{
  top:13vh;
  left:1vw;
  z-index:0;
  height:152px;
  width:192px;
  z-index:24;scale:0.98
}
.fa-brands.fa-instagram {
  left:6vw;
}
.fa-brands.fa-x-twitter {
  left:6vw;
}


.Rss{
  top:11vh;right:-19vw;scale:0.8
    }

  .sigma{
    top:47vh;left:33vw;height:170px;
  }
  .jely{
    display:none;
  }



  .meat{
    top:7vh;
    left:6vw;
    scale:0.95
}


.h{
top:35vh;right:3.3vw;font-size:37px;
}

.ha{
top:44.7vh; right:3.3vw;font-size:37px;
}
.hac{
top:54vh;right:2.3vw;font-size:37px;
}

.chpain{
right:-63vw;
}
.swy{
top:733vh;left:16vw;font-size:110px; font-family:britney;color:black;z-index:7
}
.swyy{
top:756vh;left:16vw;font-size:40px; font-family:britney;color:rgb(255, 255, 255);z-index:7;width:740px;cursor:default;
}
.Emp{
top:735vh;right:29vw;
}

 .use{
  top:940vh;left:22vw;font-size: 160px;font-family:Montserrat;color:rgb(87, 0, 52);opacity:0;cursor:default;
 }

 .war{
  top:1126vh;right:-205vw;scale:0.84
 }
.Finish{
  top:1222vh;right:-75vw;font-size: 77px;
 }
 .rainbow {
  top:1234vh;left:12vw;font-size: 90px;
}
.us{
  top:910vh;left:25vw;font-size: 160px;font-family:britney;color:rgb(140, 140, 140);opacity:0;cursor:default;
 }

 .name{
  top:1267vh;left:13.1vw;font-size:35px;width:300px
}
.namee{
  top:1277vh;left:13.1vw;width:450px
}
}



@media screen and (min-width: 850px) and (max-width: 900px) {  

  .Fem{
    top:154vh;left:6.8vw
  }
  .Me{
    top:153.4vh;left:-11vw;
  }

  .at{
    top:174vh; left:18vw;
  }
  .wordst{
    top:191.6vh; left:18.5vw;font-size:29px;width:350px
  }



.line{
  top:190.3vh;right:34.3vw;border-radius:20%;height:22px;width:22px;background:linear-gradient(black,black);border:solid 0.1px rgb(97, 2, 37);
}



.linee{
  top:186vh;right:35.5vw;height:350px;width:1px;background:linear-gradient(rgb(60, 0, 109),black);border-radius:50px;
}


.words33{
  top:228vh; left:21.2vw;
}

  .stz{
    top:221vh; left:13.5vw;
  }
  .dayatona{
    top:173vh;right:-40vw;height:600px; width:600px;scale:0.8
  }
  .Professional{
    top:9vh;
    left:-2vw;
    z-index:2;
    height:220px;
    z-index:25;scale:0.96
  
  }

.Wheel{
  top:13vh;
  left:1vw;
  z-index:0;
  height:152px;
  width:192px;
  z-index:24;scale:0.98
}
.fa-brands.fa-instagram {
  left:6vw;
}
.fa-brands.fa-x-twitter {
  left:6vw;
}


.Rss{
  top:11vh;right:-23vw;scale:0.8
    }

  .sigma{
    top:48vh;left:32vw;height:180px;
  }
  .jely{
display: none; 
 }



  .meat{
    top:7vh;
    left:6vw;
    scale:0.95
}


.h{
top:35vh;right:3.3vw;font-size:37px;
}

.ha{
top:44.7vh; right:3.3vw;font-size:37px;
}
.hac{
top:54vh;right:2.3vw;font-size:37px;
}

.chpain{
right:-63vw;
}
.swy{
top:733vh;left:16vw;font-size:100px; font-family:britney;color:black;z-index:7
}
.swyy{
top:756vh;left:16vw;font-size:40px; font-family:britney;color:rgb(255, 255, 255);z-index:7;width:740px;cursor:default;
}
.Emp{
top:735vh;right:29vw;
}

 .use{
  top:940vh;left:22vw;font-size: 160px;font-family:Montserrat;color:rgb(87, 0, 52);opacity:0;cursor:default;
 }

 .war{
  top:1126vh;right:-216vw;scale:0.83
 }
.Finish{
  top:1222vh;right:-85vw;font-size: 77px;
 }
 .rainbow {
  top:1234vh;left:12vw;font-size: 90px;
}
.us{
  top:910vh;left:25vw;font-size: 160px;font-family:britney;color:rgb(140, 140, 140);opacity:0;cursor:default;
 }

 .name{
  top:1267vh;left:13.1vw;font-size:33px;width:280px
}
.namee{
  top:1277vh;left:13.1vw;width:420px
}
}






@media screen and (min-width: 800px) and (max-width: 850px) {  

  .Fem{
    top:154vh;left:4.8vw
  }
  .Me{
    top:154.4vh;left:-13vw;
  }

  .at{
    top:174vh; left:18vw;font-size:70px
  }
  .wordst{
    top:190vh; left:18.5vw;font-size:30px;width:300px
  }



.line{
  top:190.3vh;right:37.3vw;border-radius:20%;height:22px;width:22px;background:linear-gradient(black,black);border:solid 0.1px rgb(97, 2, 37);
}



.linee{
  top:186vh;right:38.5vw;height:350px;width:1px;background:linear-gradient(rgb(60, 0, 109),black);border-radius:50px;
}


.words33{
  top:238vh; left:19.2vw;
}

  .stz{
    top:231vh; left:11.5vw;
  }
  .dayatona{
    top:165vh;right:-41vw;height:600px; width:600px;scale:0.8
  }
  .Professional{
    top:9vh;
    left:-4vw;
    z-index:2;
    height:220px;
    z-index:25;scale:0.96
  
  }

.Wheel{
  top:13vh;
  left:-1vw;
  z-index:0;
  height:152px;
  width:192px;
  z-index:24;scale:0.98
}
.fa-brands.fa-instagram {
  left:6vw;
}
.fa-brands.fa-x-twitter {
  left:6vw;
}


.Rss{
  top:11vh;right:-31vw;scale:0.8
    }

  .sigma{
    top:48vh;left:34.5vw;height:160px;
  }
  .jely{
    display: none; 
     }



  .meat{
    top:10vh;
    left:0vw;
    scale:0.9
}


.h{
top:35vh;right:0.3vw;font-size:34px;
}

.ha{
top:42.7vh; right:0.3vw;font-size:34px;
}
.hac{
top:51vh;right:.1vw;font-size:34px;
}


.swy{
top:735vh;left:16vw;font-size:100px; font-family:britney;color:black;z-index:7
}
.swyy{
top:756vh;left:16vw;font-size:40px; font-family:britney;color:rgb(255, 255, 255);z-index:7;width:700px;cursor:default;
}
.Emp{
top:735vh;right:32vw;scale:0.9
}

 .use{
  top:940vh;left:22vw;font-size: 160px;font-family:Montserrat;color:rgb(87, 0, 52);opacity:0;cursor:default;
 }

 .war{
  top:1126vh;right:-232vw;scale:0.8
 }
.Finish{
  top:1222vh;right:-95vw;font-size: 77px;
 }
 .rainbow {
  top:1234vh;left:12vw;font-size: 90px;
}
.us{
  top:910vh;left:25vw;font-size: 160px;font-family:britney;color:rgb(140, 140, 140);opacity:0;cursor:default;
 }

 .name{
  top:1267vh;left:13.1vw;font-size:33px;width:280px
}
.namee{
  top:1277vh;left:13.1vw;width:370px
}




.chpain{
right:-68vw; scale:0.8;top:510vh
}





.Sauvage{
  scale:0.95
}
}



@media screen and (min-width: 750px) and (max-width: 800px) {  

  .Fem{
    top:154vh;left:4.8vw
  }
  .Me{
    top:154.4vh;left:-17vw;
  }

  .at{
    top:174vh; left:18vw;font-size:70px
  }
  .wordst{
    top:190vh; left:18.5vw;font-size:30px;width:300px
  }



.line{
  top:190.3vh;right:37.3vw;border-radius:20%;height:22px;width:22px;background:linear-gradient(black,black);border:solid 0.1px rgb(97, 2, 37);
}



.linee{
  top:186vh;right:38.5vw;height:350px;width:1px;background:linear-gradient(rgb(60, 0, 109),black);border-radius:50px;
}


.words33{
  top:238vh; left:19.2vw;
}

  .stz{
    top:231vh; left:11vw;
  }
  .dayatona{
    top:165vh;right:-41vw;height:600px; width:600px;scale:0.8
  }
  .Professional{
    top:9vh;
    left:-4vw;
    z-index:2;
    height:220px;
    z-index:25;scale:0.96
  
  }

.Wheel{
  top:13vh;
  left:-1vw;
  z-index:0;
  height:152px;
  width:192px;
  z-index:24;scale:0.98
}
.fa-brands.fa-instagram {
  left:6vw;
}
.fa-brands.fa-x-twitter {
  left:6vw;
}


.Rss{
  top:11vh;right:-34vw;scale:0.7
    }

  .sigma{
    top:48vh;left:34vw;height:150px;
  }
  .jely{
    display: none; 
     }


  .meat{
    top:10vh;
    left:0vw;
    scale:0.9
}


.h{
top:35vh;right:0.3vw;font-size:34px;
}

.ha{
top:42.7vh; right:0.3vw;font-size:34px;
}
.hac{
top:51vh;right:.1vw;font-size:34px;
}


.swy{
top:740vh;left:16vw;font-size:85px; font-family:britney;color:black;z-index:7
}
.swyy{
top:756vh;left:16vw;font-size:40px; font-family:britney;color:rgb(255, 255, 255);z-index:7;width:700px;cursor:default;
}
.Emp{
top:735vh;right:18vw;scale:0.9
}

 .use{
  top:950vh;left:22vw;font-size: 130px;font-family:Montserrat;color:rgb(87, 0, 52);opacity:0;cursor:default;
 }
 .us{
  top:930vh;left:25vw;font-size: 130px;font-family:britney;color:rgb(140, 140, 140);opacity:0;cursor:default;
 }
 .war{
  top:1130vh;right:-250vw;scale:0.78
 }
.Finish{
  top:1222vh;right:-107vw;font-size: 77px;
 }
 .rainbow {
  top:1234vh;left:12vw;font-size: 90px;
}


 .name{
  top:1267vh;left:13.1vw;font-size:33px;width:280px
}
.namee{
  top:1277vh;left:13.1vw;width:370px
}




.chpain{
right:-68vw; scale:0.8;top:510vh
}





.Sauvage{
  scale:0.95
}
}
@media screen and (min-width: 700px) and (max-width: 750px) {  

  .Fem{
    top:154vh;left:4.8vw
  }
  .Me{
    top:154.4vh;left:-17vw;
  }

  .at{
    top:174vh; left:18vw;font-size:70px
  }
  .wordst{
    top:190vh; left:18.5vw;font-size:30px;width:300px
  }



.line{
  top:190.3vh;right:37.3vw;border-radius:20%;height:22px;width:22px;background:linear-gradient(black,black);border:solid 0.1px rgb(97, 2, 37);
}



.linee{
  top:186vh;right:38.5vw;height:350px;width:1px;background:linear-gradient(rgb(60, 0, 109),black);border-radius:50px;
}


.words33{
  top:238vh; left:19.2vw;
}

  .stz{
    top:231vh; left:10.5vw;
  }
  .dayatona{
    top:167vh;right:-40vw;height:600px; width:600px;scale:0.8
  }
  .Professional{
    top:9vh;
    left:-4vw;
    z-index:2;
    height:220px;
    z-index:25;scale:0.96
  
  }

.Wheel{
  top:13vh;
  left:-1vw;
  z-index:0;
  height:152px;
  width:192px;
  z-index:24;scale:0.98
}
.fa-brands.fa-instagram {
  left:6vw;
}
.fa-brands.fa-x-twitter {
  left:6vw;
}


.Rss{
  top:11vh;right:-40vw;scale:0.65
    }

  .sigma{
    top:48vh;left:35vw;height:130px;
  }
  .jely{
    display: none; 
     }



  .meat{
    top:10vh;
    left:0vw;
    scale:0.9
}


.h{
top:35vh;right:0.3vw;font-size:34px;
}

.ha{
top:42.7vh; right:0.3vw;font-size:34px;
}
.hac{
top:51vh;right:.1vw;font-size:34px;
}


.swy{
top:740vh;left:16vw;font-size:85px; font-family:britney;color:black;z-index:7
}
.swyy{
top:756vh;left:16vw;font-size:37px; font-family:britney;color:rgb(255, 255, 255);z-index:7;width:600px;cursor:default;
}
.Emp{
top:735vh;right:18vw;scale:0.9
}

 .use{
  top:950vh;left:22vw;font-size: 130px;font-family:Montserrat;color:rgb(87, 0, 52);opacity:0;cursor:default;
 }
 .us{
  top:930vh;left:25vw;font-size: 130px;font-family:britney;color:rgb(140, 140, 140);opacity:0;cursor:default;
 }
 .war{
  top:1130vh;right:-267vw;scale:0.78
 }
.Finish{
  top:1226vh;right:-122vw;font-size: 60px;
 }
 .Finishh{
  top:1256vh;left:15.2vw;font-size: 37px;
 }
 .rainbow {
  top:1234vh;left:13vw;font-size: 90px;
}


 .name{
  top:1267vh;left:15.1vw;font-size:30px;width:200px
}
.namee{
  top:1277vh;left:15.1vw;width:290px; font-size:35px
}




.chpain{
right:-68vw; scale:0.8;top:510vh
}



.button-85 {
  height:55px;
  width:230px;
  top:1288vh;
  left:15vw;
}

.Sauvage{
  scale:0.95
}
}




@media screen and (min-width: 650px) and (max-width: 700px) {  

  .Fem{
    top:151vh;left:-2.8vw
  }
  .Me{
    top:152.4vh;left:-17vw;
  }

  .at{
    top:171vh; left:16vw;font-size:70px
  }




.line{
  top:198.3vh;right:36.8vw;border-radius:20%;height:22px;width:22px;background:linear-gradient(black,black);border:solid 0.1px rgb(97, 2, 37);
}



.linee{
  top:194vh;right:38.5vw;height:300px;width:1px;background:linear-gradient(rgb(60, 0, 109),black);border-radius:50px;
}


.words33{
  top:233vh; left:21vw;
}

  .stz{
    top:226vh; left:11.5vw;
  }
  .dayatona{
    top:172vh;right:-43vw;height:600px; width:600px;scale:0.74
  }
  .wordst{
    top:190vh; left:18.5vw;font-size:28px;width:270px
  }
  .Professional{
    top:9vh;
    left:-6vw;
    z-index:2;
    height:220px;
    z-index:25;scale:0.9
  
  }

.Wheel{
  top:13vh;
  left:-3vw;
  z-index:0;
  height:152px;
  width:192px;
  z-index:24;scale:0.95
}
.fa-brands.fa-instagram {
  left:4vw;font-size:27px
}
.fa-brands.fa-x-twitter {
  left:4vw;font-size:27px;    top:60vh;
}


.Rss{
  top:14vh;right:-45vw;scale:0.67
    }

  .sigma{
left:30vw;height:160px;
  }
  .jely{
    display: none; 
     }



  .meat{
    top:15vh;
    left:-7vw;
    scale:0.88
}


.h{
top:35vh;right:0.3vw;font-size:34px;
}

.ha{
top:42.7vh; right:0.3vw;font-size:34px;
}
.hac{
top:51vh;right:.1vw;font-size:34px;
}


.swy{
top:742vh;left:16vw;font-size:82px; font-family:britney;color:black;z-index:7;white-space:nowrap
}
.swyy{
top:756vh;left:16vw;font-size:37px; font-family:britney;color:rgb(255, 255, 255);z-index:7;width:500px;cursor:default;
}
.Emp{
top:735vh;right:18vw;scale:0.9
}

 .use{
  top:950vh;left:22vw;font-size: 130px;font-family:Montserrat;color:rgb(87, 0, 52);opacity:0;cursor:default;
 }
 .us{
  top:930vh;left:25vw;font-size: 130px;font-family:britney;color:rgb(140, 140, 140);opacity:0;cursor:default;
 }
 .war{
  top:1130vh;right:-297vw;scale:0.75
 }
.Finish{
  top:1226vh;right:-138vw;font-size: 67px;
 }
 .Finishh{
  top:1256vh;left:15.2vw;font-size: 37px;
 }
 .rainbow {
  top:1234vh;left:13vw;font-size: 90px;
}


 .name{
  top:1267vh;left:15.1vw;font-size:30px;width:200px
}
.namee{
  top:1277vh;left:15.1vw;width:290px; font-size:35px
}




.chpain{
right:-88vw; scale:0.8;top:510vh
}



.button-85 {
  height:55px;
  width:230px;
  top:1288vh;
  left:15vw;
}

.Sauvage{
  scale:0.95
}
}



@media screen and (min-width: 600px) and (max-width: 650px) {  

  .Fem{
    top:158vh;left:-14.8vw
  }
  .Me{
    top:159.4vh;left:-37vw;
  }

  .at{
    top:178vh; left:6vw;font-size:70px
  }




.line{
  top:203.3vh;right:35.8vw;border-radius:20%;height:22px;width:22px;background:linear-gradient(black,black);border:solid 0.1px rgb(97, 2, 37);
}



.linee{
  top:197vh;right:37.5vw;height:300px;width:1px;background:linear-gradient(rgb(60, 0, 109),black);border-radius:50px;
}


.words33{
  top:233vh; left:8.4vw;
}

  .stz{
    top:226vh; left:-2vw;
  }
  .dayatona{
    top:174vh;right:-43vw;height:600px; width:600px;scale:0.72
  }
  .wordst{
    top:195vh; left:5.5vw;font-size:28px;width:330px
  }
  .Professional{
    top:1vh;
    left:-6vw;
    z-index:2;
    height:220px;
    z-index:25;scale:0.95
  
  }

.Wheel{
  top:5vh;
  left:-2vw;
  z-index:0;
  height:152px;
  width:192px;
  z-index:24;scale:0.95
}
.fa-brands.fa-instagram {
  left:92vw;font-size:27px;top:10vh
}
.fa-brands.fa-x-twitter {
  left:92vw;font-size:27px;    top:17vh;
}


.Rss{
  top:14vh;right:-55vw;scale:0.8
    }

  .sigma{
left:29vw;height:170px;
  }
  .jely{
    display: none; 
     }



  .meat{
    top:11vh;
    left:-15vw;
    scale:0.9
}


.h{
top:35vh;right:0.3vw;font-size:34px;display:none;

}

.ha{
top:42.7vh; right:0.3vw;font-size:34px;display:none;

}
.hac{
top:51vh;right:.1vw;font-size:34px;display:none;

}


.swy{
top:742vh;left:7vw;font-size:82px; font-family:britney;color:black;z-index:7;white-space:nowrap
}
.swyy{
top:756vh;left:9vw;font-size:40px; font-family:britney;color:rgb(255, 255, 255);z-index:7;width:500px;cursor:default;
}
.Emp{
top:735vh;right:13vw;scale:0.9
}

 .use{
  top:955vh;left:16vw;font-size: 130px;font-family:Montserrat;color:rgb(87, 0, 52);opacity:0;cursor:default;
 }
 .us{
  top:935vh;left:19vw;font-size: 130px;font-family:britney;color:rgb(140, 140, 140);opacity:0;cursor:default;
 }
 .war{
  top:1135vh;right:-315vw;scale:0.73
 }
.Finish{
  top:1232vh;right:-143vw;font-size: 63px;
 }
 .Finishh{
  top:1256vh;left:3.2vw;font-size: 32px;
 }
 .rainbow {
  top:1238vh;left:1vw;font-size: 90px;
}


 .name{
  top:1267vh;left:2.1vw;font-size:30px;width:150px
}
.namee{
  top:1277vh;left:2.1vw;width:260px; font-size:35px
}




.chpain{
right:-88vw; scale:0.8;top:510vh
}



.button-85 {
  height:55px;
  width:230px;
  top:1288vh;
  left:3vw;
}

.Sauvage{
  scale:0.95
}
}

@media screen and (min-width: 600px) and (max-width: 625px) {  
  .Finish{
    top:1232vh;right:-153vw;font-size: 63px;
   }
}













@media screen and (min-width: 550px) and (max-width: 600px) {  

  .Fem{
    top:158vh;left:-17.8vw
  }
  .Me{
    top:159.4vh;left:-37vw;
  }

  .at{
    top:178vh; left:3vw;font-size:70px
  }




.line{
  top:203.3vh;right:35.8vw;border-radius:20%;height:22px;width:22px;background:linear-gradient(black,black);border:solid 0.1px rgb(97, 2, 37);
}



.linee{
  top:197vh;right:37.5vw;height:300px;width:1px;background:linear-gradient(rgb(60, 0, 109),black);border-radius:50px;
}


.words33{
  top:233vh; left:6.4vw;
}

  .stz{
    top:226vh; left:-5vw;
  }
  .dayatona{
    top:177vh;right:-45vw;height:600px; width:600px;scale:0.7
  }
  .wordst{
    top:195vh; left:2.5vw;font-size:26px;width:300px
  }
  .Professional{
    top:1vh;
    left:-6vw;
    z-index:2;
    height:220px;
    z-index:25;scale:0.95
  
  }

.Wheel{
  top:5vh;
  left:-2vw;
  z-index:0;
  height:152px;
  width:192px;
  z-index:24;scale:0.95
}
.fa-brands.fa-instagram {
  left:92vw;font-size:27px;top:10vh
}
.fa-brands.fa-x-twitter {
  left:92vw;font-size:27px;    top:17vh;
}


.Rss{
  top:14vh;right:-67vw;scale:0.72
    }

  .sigma{
    top:49vh;left:26vw;height:170px;
  }
  .jely{
    display: none; 
     }


  .meat{
    top:11vh;
    left:-15vw;
    scale:0.9
}


.h{
top:35vh;right:0.3vw;font-size:34px;display:none;

}

.ha{
top:42.7vh; right:0.3vw;font-size:34px;display:none;

}
.hac{
top:51vh;right:.1vw;font-size:34px;display:none;

}


.swy{
top:742vh;left:3vw;font-size:77px; font-family:britney;color:black;z-index:7;white-space:nowrap
}
.swyy{
top:756vh;left:4vw;font-size:36px; font-family:britney;color:rgb(255, 255, 255);z-index:7;width:500px;cursor:default;
}
.Emp{
top:735vh;right:13vw;scale:0.9
}

 .use{
  top:955vh;left:16vw;font-size: 130px;font-family:Montserrat;color:rgb(87, 0, 52);opacity:0;cursor:default;
 }
 .us{
  top:935vh;left:19vw;font-size: 130px;font-family:britney;color:rgb(140, 140, 140);opacity:0;cursor:default;
 }
 .war{
  top:1135vh;right:-360vw;scale:0.73
 }
.Finish{
  top:1232vh;right:-175vw;font-size: 61px;
 }
 .Finishh{
  top:1256vh;left:3.2vw;font-size: 32px;
 }
 .rainbow {
  top:1238vh;left:0vw;font-size: 90px;
}


 .name{
  top:1267vh;left:2.1vw;font-size:30px;width:150px
}
.namee{
  top:1277vh;left:2.1vw;width:260px; font-size:35px
}




.chpain{
right:-108vw; scale:0.8;top:515vh
}



.button-85 {
  height:55px;
  width:230px;
  top:1288vh;
  left:3vw;
}

.Sauvage{
  scale:0.93
}
}

@media screen and (min-width: 575px) and (max-width: 600px) {  
  .war{
    top:1135vh;right:-345vw;scale:0.73
   }
  .Finish{
    top:1232vh;right:-164vw;font-size: 61px;
   }
   .Finishh{
    top:1256vh;left:3.2vw;font-size: 32px;
   }
  .Rss{
    top:14vh;right:-60vw;scale:0.72
      }

    .sigma{
      top:52vh;left:26vw;height:170px;
    }
    .jely{
      display: none; 
       }
  
  
    .meat{
      top:11vh;
      left:-15vw;
      scale:0.9
  }
  
  
}










@media screen and (min-width: 500px) and (max-width: 550px) {  

  .Fem{
    top:158vh;left:-17.8vw
  }
  .Me{
    top:159.4vh;left:-37vw;
  }

  .at{
    top:178vh; left:3vw;font-size:70px
  }




.line{
  top:203.3vh;right:45.8vw;border-radius:20%;height:22px;width:22px;background:linear-gradient(black,black);border:solid 0.1px rgb(97, 2, 37);
}



.linee{
  top:197vh;right:47.5vw;height:300px;width:1px;background:linear-gradient(rgb(60, 0, 109),black);border-radius:50px;
}


.words33{
  top:233vh; left:7.7vw;
}

  .stz{
    top:226vh; left:-5vw;
  }
  .dayatona{
    top:162vh;right:-40vw;height:600px; width:600px;scale:0.73
  }
  .wordst{
    top:195vh; left:2.5vw;font-size:26px;width:250px
  }
  .Professional{
    top:1vh;
    left:-6vw;
    z-index:2;
    height:220px;
    z-index:25;scale:0.95
  
  }

.Wheel{
  top:5vh;
  left:-2vw;
  z-index:0;
  height:152px;
  width:192px;
  z-index:24;scale:0.95
}
.fa-brands.fa-instagram {
  left:92vw;font-size:27px;top:10vh
}
.fa-brands.fa-x-twitter {
  left:92vw;font-size:27px;    top:17vh;
}


.Rss{
  top:14vh;right:-74vw;scale:0.65
}
  .sigma{
    top:51vh;left:24vw;height:150px;
  }
  .jely{
    display: none; 
     }



  .meat{
    top:11vh;
    left:-24vw;
    scale:0.88
}


.h{
top:35vh;right:0.3vw;font-size:34px;display:none;

}

.ha{
top:42.7vh; right:0.3vw;font-size:34px;display:none;

}
.hac{
top:51vh;right:.1vw;font-size:34px;display:none;

}


.swy{
top:742vh;left:1vw;font-size:74px; font-family:britney;color:black;z-index:7;white-space:nowrap
}
.swyy{
top:756vh;left:4vw;font-size:36px; font-family:britney;color:rgb(255, 255, 255);z-index:7;width:450px;cursor:default;
}
.Emp{
top:735vh;right:13vw;scale:0.9
}

 .use{
  top:955vh;left:16vw;font-size: 130px;font-family:Montserrat;color:rgb(87, 0, 52);opacity:0;cursor:default;
 }
 .us{
  top:935vh;left:19vw;font-size: 130px;font-family:britney;color:rgb(140, 140, 140);opacity:0;cursor:default;
 }
 .war{
  top:1135vh;right:-398vw;scale:0.7
 }
.Finish{
  top:1232vh;right:-199vw;font-size: 61px;
 }
 .Finishh{
  top:1256vh;left:3.2vw;font-size: 32px;
 }
 .rainbow {
  top:1238vh;left:0vw;font-size: 90px;
}


 .name{
  top:1267vh;left:2.1vw;font-size:30px;width:150px
}
.namee{
  top:1277vh;left:2.1vw;width:260px; font-size:35px
}




.chpain{
right:-108vw; scale:0.8;top:515vh
}



.button-85 {
  height:55px;
  width:230px;
  top:1288vh;
  left:3vw;
}

.Sauvage{
  scale:0.93
}
}

@media screen and (min-width: 525px) and (max-width: 550px) {  
  .Rss{
    top:14vh;right:-71vw;scale:0.65
      }


    .jely{
      display: none; 
       }

  
    .war{
      top:1135vh;right:-374vw;scale:0.7
     }
    .Finish{
      top:1232vh;right:-184vw;font-size: 61px;
     }
     .Finishh{
      top:1256vh;left:3.2vw;font-size: 32px;
     }
}

@media screen and (min-width: 450px) and (max-width: 500px) {  

  .Fem{
    top:158vh;left:-23.8vw
  }
  .Me{
    top:159.4vh;left:-36vw;
  }

  .at{
    top:178vh; left:3vw;font-size:70px
  }




.line{
  top:203.3vh;right:45.8vw;border-radius:20%;height:22px;width:22px;background:linear-gradient(black,black);border:solid 0.1px rgb(97, 2, 37);
}



.linee{
  top:197vh;right:47.5vw;height:300px;width:1px;background:linear-gradient(rgb(60, 0, 109),black);border-radius:50px;
}


.words33{
  top:233vh; left:7.7vw;
}

  .stz{
    top:226vh; left:-7vw;
  }
  .dayatona{
    top:165vh;right:-40vw;height:600px; width:600px;scale:0.74
  }
  .wordst{
    top:195vh; left:2.5vw;font-size:24px;width:200px
  }
  .Professional{
    top:1vh;
    left:-6vw;
    z-index:2;
    height:220px;
    z-index:25;scale:0.95
  
  }

.Wheel{
  top:5vh;
  left:-2vw;
  z-index:0;
  height:152px;
  width:192px;
  z-index:24;scale:0.95
}
.fa-brands.fa-instagram {
  left:92vw;font-size:27px;top:10vh
}
.fa-brands.fa-x-twitter {
  left:92vw;font-size:27px;    top:17vh;
}


.Rss{
  top:14vh;right:-89vw;scale:0.65
    }
  .sigma{
    top:49vh;left:23vw;height:150px;
  }
  .jely{
    display: none; 
     }


  .meat{
    top:12vh;
    left:-24vw;
    scale:0.88
}


.h{
top:35vh;right:0.3vw;font-size:34px;display:none;

}

.ha{
top:42.7vh; right:0.3vw;font-size:34px;display:none;

}
.hac{
top:51vh;right:.1vw;font-size:34px;display:none;

}


.swy{
top:744vh;left:1vw;font-size:66px; font-family:britney;color:black;z-index:7;white-space:nowrap
}
.swyy{
top:756vh;left:4vw;font-size:36px; font-family:britney;color:rgb(255, 255, 255);z-index:7;width:400px;cursor:default;
}
.Emp{
top:733vh;right:1vw;scale:0.9
}

 .use{
  top:960vh;left:14vw;font-size: 110px;font-family:Montserrat;color:rgb(87, 0, 52);opacity:0;cursor:default;white-space:nowrap;
 }
 .us{
  top:940vh;left:17vw;font-size: 110px;font-family:britney;color:rgb(140, 140, 140);opacity:0;cursor:default;white-space:nowrap;
 }
 .war{
  top:1135vh;right:-448vw;scale:0.67
 }
.Finish{
  top:1231vh;right:-235vw;font-size: 50px;
 }
 .Finishh{
  top:1256vh;left:3.2vw;font-size: 32px;
 }
 .rainbow {
  top:1238vh;left:0vw;font-size: 70px;
}


 .name{
  top:1267vh;left:2.1vw;font-size:30px;width:150px
}
.namee{
  top:1277vh;left:2.1vw;width:260px; font-size:35px
}




.chpain{
right:-128vw; scale:0.8;top:495vh
}



.button-85 {
  height:55px;
  width:230px;
  top:1288vh;
  left:3vw;
}

.Sauvage{
  scale:0.93
}
}

@media screen and (min-width: 465px) and (max-width: 500px) {  
  .war{
    top:1135vh;right:-430vw;scale:0.7
   }
  .Finish{
    top:1231vh;right:-223vw;font-size: 50px;
   }
   .Finishh{
    top:1256vh;left:3.2vw;font-size: 32px;
   }
   .rainbow {
    top:1238vh;left:0vw;font-size: 70px;
  }
  
  
   .name{
    top:1267vh;left:2.1vw;font-size:30px;width:150px
  }
  .namee{
    top:1277vh;left:2.1vw;width:260px; font-size:35px
  }



  

.Rss{
  top:14vh;right:-83vw;scale:0.67
    }

  .sigma{
    top:49vh;left:23vw;height:150px;
  }
  .jely{
    display:none;opacity:0

  }



  .meat{
    top:12vh;
    left:-24vw;
    scale:0.88
}
}

@media screen and (min-width: 485px) and (max-width: 500px) {  
  .war{
    top:1135vh;right:-415vw;scale:0.7
   }
  .Finish{
    top:1231vh;right:-211vw;font-size: 50px;
   }
   .Finishh{
    top:1256vh;left:3.2vw;font-size: 32px;
   }
   .rainbow {
    top:1238vh;left:0vw;font-size: 70px;
  }
  
  .finger{
    height:38vh;width:110vw;top:900vh;right:-4vw;
  
   }


   .use{
    top:950vh;left:12vw;font-size: 110px;font-family:Montserrat;color:rgb(87, 0, 52);opacity:0;cursor:default;white-space:nowrap;
   }
   .us{
    top:930vh;left:15vw;font-size: 110px;font-family:britney;color:rgb(140, 140, 140);opacity:0;cursor:default;white-space:nowrap;
   }
   .name{
    top:1267vh;left:2.1vw;font-size:30px;width:150px
  }
  .namee{
    top:1277vh;left:2.1vw;width:260px; font-size:35px
  }
}








@media screen and (min-width: 400px) and (max-width: 450px) {  

  .Fem{
    top:158vh;left:-23.8vw
  }
  .Me{
    top:159.4vh;left:-36vw;
  }

  .at{
    top:178vh; left:3vw;font-size:70px
  }




.line{
  top:203.3vh;right:45.8vw;border-radius:20%;height:22px;width:22px;background:linear-gradient(black,black);border:solid 0.1px rgb(97, 2, 37);
}



.linee{
  top:197vh;right:47.5vw;height:300px;width:1px;background:linear-gradient(rgb(60, 0, 109),black);border-radius:50px;
}


.words33{
  top:233vh; left:7.7vw;
}

  .stz{
    top:226vh; left:-7vw;
  }
  .dayatona{
    top:165vh;right:-40vw;height:600px; width:600px;scale:0.74
  }
  .wordst{
    top:195vh; left:2.5vw;font-size:24px;width:200px
  }
  .Professional{
    top:1vh;
    left:-6vw;
    z-index:2;
    height:220px;
    z-index:25;scale:0.95
  
  }

.Wheel{
  top:5vh;
  left:-2vw;
  z-index:0;
  height:152px;
  width:192px;
  z-index:24;scale:0.95
}
.fa-brands.fa-instagram {
  left:92vw;font-size:27px;top:10vh
}
.fa-brands.fa-x-twitter {
  left:92vw;font-size:27px;    top:17vh;
}


.Rss{
  top:14vh;right:-89vw;scale:0.65
    }

  .sigma{
    top:49vh;left:23vw;height:150px;
  }
  .jely{
    display:none;opacity:0
  }


  .meat{
    top:12vh;
    left:-24vw;
    scale:0.88
}


.h{
top:35vh;right:0.3vw;font-size:34px;display:none;

}

.ha{
top:42.7vh; right:0.3vw;font-size:34px;display:none;

}
.hac{
top:51vh;right:.1vw;font-size:34px;display:none;

}


.swy{
top:744vh;left:1vw;font-size:66px; font-family:britney;color:black;z-index:7;white-space:nowrap
}
.swyy{
top:756vh;left:4vw;font-size:36px; font-family:britney;color:rgb(255, 255, 255);z-index:7;width:400px;cursor:default;
}
.Emp{
top:733vh;right:1vw;scale:0.9
}

 .use{
  top:960vh;left:14vw;font-size: 110px;font-family:Montserrat;color:rgb(87, 0, 52);opacity:0;cursor:default;white-space:nowrap;
 }
 .us{
  top:940vh;left:17vw;font-size: 110px;font-family:britney;color:rgb(140, 140, 140);opacity:0;cursor:default;white-space:nowrap;
 }
 .war{
  display:none;
 }
.Finish{
  top:1231vh;right:-235vw;font-size: 50px;
 }
 .Finishh{
  top:1256vh;left:3.2vw;font-size: 32px;
 }
 .rainbow {
  top:1238vh;left:0vw;font-size: 70px;
}


 .name{
  top:1267vh;left:2.1vw;font-size:30px;width:150px
}
.namee{
  top:1277vh;left:2.1vw;width:260px; font-size:35px
}




.chpain{
right:-158vw; scale:0.8;top:495vh
}



.button-85 {
  height:55px;
  width:230px;
  top:1288vh;
  left:3vw;
}

.Sauvage{
  scale:0.93
}
}











@media screen and (min-width: 425px) and (max-width: 450px) {  
  .finger{
    height:38vh;width:110vw;top:900vh;right:-4vw;
  
   }


   
  .Fem{
    top:158vh;left:-23.8vw
  }
  .Me{
    top:159.4vh;left:-36vw;
  }

  .at{
    top:178vh; left:3vw;font-size:70px
  }




.line{
  top:203.3vh;right:45.8vw;border-radius:20%;height:22px;width:22px;background:linear-gradient(black,black);border:solid 0.1px rgb(97, 2, 37);
}



.linee{
  top:197vh;right:47.5vw;height:300px;width:1px;background:linear-gradient(rgb(60, 0, 109),black);border-radius:50px;
}


.words33{
  top:233vh; left:7.7vw;
}

  .stz{
    top:226vh; left:-7vw;
  }
  .dayatona{
    top:165vh;right:-40vw;height:600px; width:600px;scale:0.74
  }
  .wordst{
    top:195vh; left:2.5vw;font-size:24px;width:200px
  }
  .Professional{
    top:1vh;
    left:-6vw;
    z-index:2;
    height:220px;
    z-index:25;scale:0.95
  
  }

.Wheel{
  top:5vh;
  left:-2vw;
  z-index:0;
  height:152px;
  width:192px;
  z-index:24;scale:0.95
}
.fa-brands.fa-instagram {
  left:92vw;font-size:27px;top:10vh
}
.fa-brands.fa-x-twitter {
  left:92vw;font-size:27px;    top:17vh;
}


.Rss{
  top:14vh;right:-99vw;scale:0.6
    }

  .sigma{
    top:50vh;left:27vw;height:135px;
  }
  .jely{
    top:46vh; left:-24vw; height:210px
  }




  .meat{
    top:15vh;
    left:-30vw;
    scale:0.86
}


.h{
top:35vh;right:0.3vw;font-size:34px;display:none;

}

.ha{
top:42.7vh; right:0.3vw;font-size:34px;display:none;

}
.hac{
top:51vh;right:.1vw;font-size:34px;display:none;

}


.swy{
top:744vh;left:1vw;font-size:66px; font-family:britney;color:black;z-index:7;white-space:nowrap
}
.swyy{
top:756vh;left:4vw;font-size:36px; font-family:britney;color:rgb(255, 255, 255);z-index:7;width:400px;cursor:default;
}
.Emp{
top:733vh;right:1vw;scale:0.9
}

 .use{
  top:950vh;left:12vw;font-size: 110px;font-family:Montserrat;color:rgb(87, 0, 52);opacity:0;cursor:default;white-space:nowrap;
 }
 .us{
  top:930vh;left:15vw;font-size: 110px;font-family:britney;color:rgb(140, 140, 140);opacity:0;cursor:default;white-space:nowrap;
 }
 .war{
  display:none;
 }
.Finish{
  top:1202vh;right:-251vw;font-size: 46px;
 }
 .Finishh{
  top:1226vh;left:3.2vw;font-size: 32px;
 }
 .rainbow {
  top:1208vh;left:0vw;font-size: 70px;
}


 .name{
  top:1237vh;left:2.1vw;font-size:30px;width:180px
}
.namee{
  top:1247vh;left:2.1vw;width:300px; font-size:35px
}




.chpain{
right:-128vw; scale:0.8;top:495vh
}



.button-85 {
  height:55px;
  width:230px;
  top:1258vh;
  left:3vw;
}

.Sauvage{
  scale:0.93
}
.credi{
  font-size:60px;
  top:1410vh;
  left:17vw;
}
.vidt{
  top:1077vh;left:3vw; height:50vh;width:90vw;
}

}











@media screen and (min-width: 400px) and (max-width: 425px) {  

  .Fem{
    top:158vh;left:-23.8vw
  }
  .Me{
    top:159.4vh;left:-40vw;
  }

  .at{
    top:178vh; left:3vw;font-size:70px
  }




.line{
  top:203.3vh;right:41.8vw;border-radius:20%;height:22px;width:22px;background:linear-gradient(black,black);border:solid 0.1px rgb(97, 2, 37);
}



.linee{
  top:197vh;right:43.5vw;height:300px;width:1px;background:linear-gradient(rgb(60, 0, 109),black);border-radius:50px;
}


.words33{
  top:233vh; left:7.7vw;
}

  .stz{
    top:226vh; left:-8vw;
  }
  .dayatona{
    top:175vh;right:-42vw;height:600px; width:600px;scale:0.68
  }
  .wordst{
    top:195vh; left:2.5vw;font-size:24px;width:200px
  }
  .Professional{
    top:1vh;
    left:-6vw;
    z-index:2;
    height:220px;
    z-index:25;scale:0.95
  
  }

.Wheel{
  top:5vh;
  left:-2vw;
  z-index:0;
  height:152px;
  width:192px;
  z-index:24;scale:0.95
}
.fa-brands.fa-instagram {
  left:92vw;font-size:27px;top:10vh
}
.fa-brands.fa-x-twitter {
  left:92vw;font-size:27px;    top:17vh;
}


.Rss{
  top:14vh;right:-105vw;scale:0.6
    }

    
  .sigma{
    top:50vh;left:24vw;height:135px;
  }
  .jely{
    display:none;
  }




  .meat{
    top:15vh;
    left:-30vw;
    scale:0.86
}


.h{
top:35vh;right:0.3vw;font-size:34px;display:none;

}

.ha{
top:42.7vh; right:0.3vw;font-size:34px;display:none;

}
.hac{
top:51vh;right:.1vw;font-size:34px;display:none;

}


.swy{
top:744vh;left:1vw;font-size:57px; font-family:britney;color:black;z-index:7;white-space:nowrap
}
.swyy{
top:756vh;left:4vw;font-size:34px; font-family:britney;color:rgb(255, 255, 255);z-index:7;width:400px;cursor:default;
}
.Emp{
top:733vh;right:1vw;scale:0.9
}

 .use{
  top:955vh;left:8vw;font-size: 100px;font-family:Montserrat;color:rgb(87, 0, 52);opacity:0;cursor:default;white-space:nowrap;
 }
 .us{
  top:935vh;left:11vw;font-size: 100px;font-family:britney;color:rgb(140, 140, 140);opacity:0;cursor:default;white-space:nowrap;
 }

 .finger{
  height:40vh;width:110vw;top:900vh;right:-4vw;

 }



 .war{
  display:none;
 }
.Finish{
  top:1144vh;right:-276vw;font-size: 38px;
 }
 .Finishh{
  top:1166vh;left:3.2vw;font-size: 32px;
 }
 .rainbow {
  top:1150vh;left:0vw;font-size: 70px;
}


 .name{
  top:1177vh;left:2.1vw;font-size:30px;width:180px
}
.namee{
  top:1187vh;left:2.1vw;width:300px; font-size:35px
}


.button-85 {
  height:55px;
  width:230px;
  top:1197vh;
  left:3vw;
}
.credi{
  font-size:60px;
  top:1330vh;
  left:12vw;
}




.chpain{
right:-128vw; scale:0.8;top:495vh
}



.Sauvage{
  scale:0.93;  top:407vh;left:10vw;
}
.right{
  top:430vh;left:7vw;font-size:120px;
}

.vidt{
  top:1077vh;left:3vw; height:50vh;width:90vw;
}



}


















@media screen and (min-width: 350px) and (max-width: 400px) {  
  .stang{
    position:absolute;
    right:1200px
  }
  .Fem{
    top:156vh;left:-40.8vw
  }
  .Me{
    top:156.4vh;left:-76vw;
  }

  .at{
    top:178vh; left:3vw;font-size:60px
  }




.line{
  top:203.3vh;right:39vw;border-radius:20%;height:22px;width:22px;background:linear-gradient(black,black);border:solid 0.1px rgb(97, 2, 37);
}



.linee{
  top:197vh;right:41.5vw;height:300px;width:1px;background:linear-gradient(rgb(60, 0, 109),black);border-radius:50px;
}


.words33{
  top:239vh; left:9vw;
}

  .stz{
    top:232vh; left:-9vw;
  }
  .dayatona{
    top:182vh;right:-45vw;height:800px; width:800px;scale:0.65;overflow:visible
  }
  .wordst{
    top:195vh; left:2.5vw;font-size:23px;width:180px
  }
  .Professional{
    top:5vh;
    left:-6vw;
    z-index:2;
    height:180px;
    z-index:25;
  
  }

.Wheel{
  top:7vh;
  left:-4vw;
  z-index:0;
  height:152px;
  width:192px;
  z-index:24;scale:0.87
}
.fa-brands.fa-instagram {
  left:88vw;font-size:24px;top:10vh
}
.fa-brands.fa-x-twitter {
  left:88vw;font-size:24px;    top:17vh;
}


.Rss{
  top:14vh;right:-125vw;scale:0.56
    }

    
  .sigma{
    top:50vh;left:18vw;height:125px;
  }
  .jely{
    display:none;
  }



  .meat{
    top:18vh;
    left:-45vw;
    scale:0.82
}


.h{
top:35vh;right:0.3vw;font-size:34px;display:none;

}

.ha{
top:42.7vh; right:0.3vw;font-size:34px;display:none;

}
.hac{
top:51vh;right:.1vw;font-size:34px;display:none;

}


.swy{
top:744vh;left:1vw;font-size:53px; font-family:britney;color:black;z-index:7;white-space:nowrap
}
.swyy{
top:756vh;left:2vw;font-size:32px; font-family:britney;color:rgb(255, 255, 255);z-index:7;width:350px;cursor:default;
}
.Emp{
top:733vh;right:-20vw;scale:0.9
}

 .use{
  top:960vh;left:11vw;font-size: 90px;font-family:Montserrat;color:rgb(87, 0, 52);opacity:0;cursor:default;white-space:nowrap;
 }
 .us{
  top:940vh;left:14vw;font-size: 90px;font-family:britney;color:rgb(140, 140, 140);opacity:0;cursor:default;white-space:nowrap;
 }
 .war{
  display:none;
 }
.Finish{
  top:1144vh;right:-330vw;font-size: 40px;
 }
 .Finishh{
  top:1166vh;left:3.2vw;font-size: 32px;
 }
 .rainbow {
  top:1150vh;left:0vw;font-size: 70px;
}


 .name{
  top:1177vh;left:2.1vw;font-size:30px;width:180px
}
.namee{
  top:1187vh;left:2.1vw;width:300px; font-size:35px
}




.chpain{
right:-128vw; scale:0.8;top:495vh
}



.button-85 {
  height:55px;
  width:230px;
  top:1197vh;
  left:3vw;
}

.Sauvage{
  scale:0.93;  top:407vh;left:10vw;
}
.right{
  top:430vh;left:7vw;font-size:120px;
}
.credi{
  font-size:60px;
  top:1330vh;
  left:12vw;
}

.vidt{
  top:1077vh;left:3vw; height:50vh;width:90vw;
}



.finger{
  height:33vh;width:110vw;top:920vh;right:-4vw;

 }
}

@media screen and (min-width: 365px) and (max-width: 400px) {  
  .Fem{
    top:158vh;left:-23.8vw
  }
  .Me{
    top:159.4vh;left:-40vw;
  }

  .at{
    top:178vh; left:3vw;font-size:70px
  }




.line{
  top:203.3vh;right:41.8vw;border-radius:20%;height:22px;width:22px;background:linear-gradient(black,black);border:solid 0.1px rgb(97, 2, 37);
}



.linee{
  top:197vh;right:43.5vw;height:300px;width:1px;background:linear-gradient(rgb(60, 0, 109),black);border-radius:50px;
}


.words33{
  top:233vh; left:7.7vw;
}

  .stz{
    top:226vh; left:-10vw;
  }
  .dayatona{
    top:170vh;right:-40vw;height:600px; width:600px;scale:0.68
  }
  .wordst{
    top:195vh; left:2.5vw;font-size:24px;width:200px
  }
  .Professional{
    top:1vh;
    left:-6vw;
    z-index:2;
    height:220px;
    z-index:25;scale:0.95
  
  }

.Wheel{
  top:5vh;
  left:-2vw;
  z-index:0;
  height:152px;
  width:192px;
  z-index:24;scale:0.95
}
.fa-brands.fa-instagram {
  left:92vw;font-size:27px;top:10vh
}
.fa-brands.fa-x-twitter {
  left:92vw;font-size:27px;    top:17vh;
}


.Rss{
  top:14vh;right:-117vw;scale:0.58
    }

    
  .sigma{
    top:50vh;left:17vw;height:125px;
  }
  .jely{
    display:none;
  }



  .meat{
    top:15vh;
    left:-30vw;
    scale:0.86
}


.h{
top:35vh;right:0.3vw;font-size:34px;display:none;

}

.ha{
top:42.7vh; right:0.3vw;font-size:34px;display:none;

}
.hac{
top:51vh;right:.1vw;font-size:34px;display:none;

}


.swy{
top:744vh;left:1vw;font-size:57px; font-family:britney;color:black;z-index:7;white-space:nowrap
}
.swyy{
top:756vh;left:4vw;font-size:34px; font-family:britney;color:rgb(255, 255, 255);z-index:7;width:400px;cursor:default;
}
.Emp{
top:733vh;right:-11vw;scale:0.9
}

.use{
  top:960vh;left:11vw;font-size: 90px;font-family:Montserrat;color:rgb(87, 0, 52);opacity:0;cursor:default;white-space:nowrap;
 }
 .us{
  top:940vh;left:14vw;font-size: 90px;font-family:britney;color:rgb(140, 140, 140);opacity:0;cursor:default;white-space:nowrap;
 }
 .war{
  display:none;
 }
.Finish{
  top:1144vh;right:-312vw;font-size: 38px;
 }
 .Finishh{
  top:1166vh;left:3.2vw;font-size: 32px;
 }
 .rainbow {
  top:1150vh;left:0vw;font-size: 70px;
}


 .name{
  top:1177vh;left:2.1vw;font-size:30px;width:180px
}
.namee{
  top:1187vh;left:2.1vw;width:300px; font-size:35px
}




.chpain{
right:-128vw; scale:0.8;top:495vh
}



.button-85 {
  height:55px;
  width:230px;
  top:1197vh;
  left:3vw;
}

.Sauvage{
  scale:0.93;  top:407vh;left:10vw;
}
.right{
  top:430vh;left:7vw;font-size:120px;
}
.credi{
  font-size:60px;
  top:1330vh;
  left:12vw;
}

}




@media screen and (min-width: 1324px) and (max-width: 1390px) {  
  .jely{
    top:45.7vh; left:11vw; height:260px
  }

  .sigma{
    top:48vh;left:36vw;height:200px;
  }




}




@media screen and (min-width: 1600px) and (max-width: 1660px) {  
  .jely{
    top:45.7vh; left:16vw; height:260px
  }

  .sigma{
    top:48vh;left:37vw;height:200px;
  }
  .Rss{
   top:10vh;right:12.5vw
  }
  .words33{
  top:220vh; left:20.8vw;
}

  .meat{
  top:7vh;left:18vw
  }

}


@media screen and (min-width: 1660px) and (max-width: 1720px) {  
  .jely{
    top:45.7vh; left:18vw; height:260px
  }

  .sigma{
    top:48vh;left:39vw;height:200px;
  }
  .Rss{
   top:10vh;right:12.5vw
  }
  
  .meat{
  top:7vh;left:18vw
  }

}



















@media screen and (min-width: 380px) and (max-width: 400px) {  
  .Fem{
    top:158vh;left:-23.8vw
  }
  .Me{
    top:159.4vh;left:-40vw;
  }

  .at{
    top:178vh; left:3vw;font-size:70px
  }




.line{
  top:203.3vh;right:41.8vw;border-radius:20%;height:22px;width:22px;background:linear-gradient(black,black);border:solid 0.1px rgb(97, 2, 37);
}



.linee{
  top:197vh;right:43.5vw;height:300px;width:1px;background:linear-gradient(rgb(60, 0, 109),black);border-radius:50px;
}


.words33{
  top:233vh; left:7.7vw;
}

  .stz{
    top:226vh; left:-8vw;
  }
  .dayatona{
    top:170vh;right:-40vw;height:600px; width:600px;scale:0.68
  }
  .wordst{
    top:195vh; left:2.5vw;font-size:24px;width:200px
  }
  .Professional{
    top:1vh;
    left:-6vw;
    z-index:2;
    height:220px;
    z-index:25;scale:0.95
  
  }

.Wheel{
  top:5vh;
  left:-2vw;
  z-index:0;
  height:152px;
  width:192px;
  z-index:24;scale:0.95
}
.fa-brands.fa-instagram {
  left:92vw;font-size:27px;top:10vh
}
.fa-brands.fa-x-twitter {
  left:92vw;font-size:27px;    top:17vh;
}


.Rss{
  top:14vh;right:-117vw;scale:0.58
    }

    
  .sigma{
    top:50vh;left:24vw;height:125px;
  }
  .jely{
    display:none;
  }



  .meat{
    top:15vh;
    left:-30vw;
    scale:0.86
}


.h{
top:35vh;right:0.3vw;font-size:34px;display:none;

}

.ha{
top:42.7vh; right:0.3vw;font-size:34px;display:none;

}
.hac{
top:51vh;right:.1vw;font-size:34px;display:none;

}


.swy{
top:744vh;left:1vw;font-size:57px; font-family:britney;color:black;z-index:7;white-space:nowrap
}
.swyy{
top:756vh;left:4vw;font-size:34px; font-family:britney;color:rgb(255, 255, 255);z-index:7;width:400px;cursor:default;
}
.Emp{
top:733vh;right:1vw;scale:0.9
}

 .use{
  top:960vh;left:14vw;font-size: 110px;font-family:Montserrat;color:rgb(87, 0, 52);opacity:0;cursor:default;white-space:nowrap;
 }
 .us{
  top:940vh;left:17vw;font-size: 110px;font-family:britney;color:rgb(140, 140, 140);opacity:0;cursor:default;white-space:nowrap;
 }
 .war{
  display:none;
 }
 .use{
  top:960vh;left:11vw;font-size: 90px;font-family:Montserrat;color:rgb(87, 0, 52);opacity:0;cursor:default;white-space:nowrap;
 }
 .us{
  top:940vh;left:14vw;font-size: 90px;font-family:britney;color:rgb(140, 140, 140);opacity:0;cursor:default;white-space:nowrap;
 }
 .war{
  display:none;
 }
.Finish{
  top:1144vh;right:-297vw;font-size: 38px;
 }
 .Finishh{
  top:1166vh;left:3.2vw;font-size: 32px;
 }
 .rainbow {
  top:1150vh;left:0vw;font-size: 70px;
}


 .name{
  top:1177vh;left:2.1vw;font-size:30px;width:180px
}
.namee{
  top:1187vh;left:2.1vw;width:300px; font-size:35px
}




.chpain{
right:-128vw; scale:0.8;top:495vh
}



.button-85 {
  height:55px;
  width:230px;
  top:1197vh;
  left:3vw;
}

.Sauvage{
  scale:0.93;  top:407vh;left:10vw;
}
.right{
  top:430vh;left:7vw;font-size:120px;
}
.credi{
  font-size:60px;
  top:1330vh;
  left:12vw;
}
}













@media screen and (min-height: 600px) and (max-height: 700px) and (min-width:350px)  and (max-width:700px){  

  .fz1{
    height:100vh;
    width:18vw;
    background:rgb(3, 0, 10);
    position:fixed;
    left:-10vw;
    top:0vh;  z-index:34;
    
    }
    .fz2{
      height:100vh;
      width:15vw;
      background:rgb(3, 0, 10);
      position:fixed;
    left:0vw;
    top:0vh;
    z-index:34;
    }
      .fz3{
    
        height:100vh;
        width:18vw;
        background:rgb(3, 0, 10);
        position:fixed;
        left:10vw;
        top:0vh;
        z-index:34    }
        .fz4{
    
          height:100vh;
          width:28vw;
          background:rgb(3, 0, 10);
          position:fixed;
          left:24vw;
          top:0vh;
          z-index:34      }
          .fz5{
    
            height:100vh;
            width:38vw;
            background:rgb(3, 0, 10);
            position:fixed;
            left:68vw;
            top:0vh;
            z-index:34        }
            .fz6{
    
              height:100vh;
              width:38vw;
              background:rgb(3, 0, 10);
              position:fixed;
              left:85vw;
              top:0vh;
              z-index:34          }
      
    
              .fz7{
    
                height:100vh;
                width:38vw;
                background:rgb(3, 0, 10);
                position:fixed;
                left:43vw;
                top:0vh;
                z-index:34         
                 }
    
                 
    
            .fz11{
    
              height:100vh;
              width:8vw;
              background:rgb(3, 0, 10);
              position:fixed;
              right:5vw;
              top:0vh; z-index:34
              }
              .fz22{
    
                height:100vh;
                width:10vw;
                background:rgb(3, 0, 10);
                position:fixed;
                right:13vw;
              top:0vh;
              z-index:34            }
                .fz33{
    
                  height:100vh;
                  width:8vw;
                  background:rgb(3, 0, 10);
                  position:fixed;
                  right:21vw;
                  top:0vh;
                  z-index:34              }
                  .fz44{
    
                    height:100vh;
                    width:8vw;
                    background:rgb(3, 0, 10);
                    position:fixed;
                    right:30vw;
                    top:0vh;
                    z-index:34                }
                    .fz55{
    
                      height:100vh;
                      width:8vw;
                      background:rgb(3, 0, 10);
                      position:fixed;
                      right:36vw;
                      top:0vh;
                      z-index:34                  }
                      .fz66{
                        height:100vh;
                        width:8vw;
                        background:rgb(3, 0, 10);
                        position:fixed;
                        right:45vw;
                        top:0vh;
                        z-index:34                    
                      }
    
    
                        .ZO{
                          top:37vh; left:23vw;z-index:35;
                        }
  .stz{
    top:224vh; 
  }
  .sigma{
    top:62vh;
  }
  .Fem{
    top:153vh;left:-23.8vw
  }
  .Me{
    top:154.4vh;left:-40vw;
  }
  .dayatona{
    top:178vh;right:-40vw;height:600px; width:600px;scale:0.68
  }
  .vot{
    top:697vh;left:0vw;height:130vh;width:100vw;object-fit: fill;opacity:80%; 
  
  }
  .jely{
    top:53.7vh; left:14vw; height:300px
  }

  .scroll-btn {
  top:92vh;
  left:48.6vw;scale:0.9;
  }
  .credi{
    font-size:50px;
    top:1360vh;
    left:12vw;
  }
}
@media screen and (min-height: 850px) and (max-height: 950px) {
  
  

  .vot{
    height:130vh;
     
     }

                        .sigma{
                          top:49vh;
                        }  .jely{
                          top:44.7vh; height:280px
                        }
              
                      }




          





@media screen and (min-height: 770px) and (max-height: 840px) {
  
  

  .words33{
    top:242vh; 
  }
  .stz{
    top:234vh; 
  }
  .sigma{
    top:53vh;
  }
  .Fem{
    top:153vh;
  }
  .Me{
    top:154.4vh;
  }
  .dayatona{
    top:178vh;height:600px; width:600px;scale:0.68
  }
  .jely{
    top:46.7vh; height:300px
  }

  .scroll-btn {
  top:85vh;
scale:0.8;
  }
  .credi{
    font-size:50px;
    top:1430vh;

  }
  .war{
    top:1130vh;scale:0.7
   }
}















@media screen and (min-height: 640px) and (max-height: 700px) {  
  .stz{
    top:224vh; 
  }
  .sigma{
    top:62vh;
  }
  .Fem{
    top:153vh;left:-23.8vw
  }
  .Me{
    top:154.4vh;left:-40vw;
  }
  .dayatona{
    top:178vh;right:-40vw;height:600px; width:600px;scale:0.68
  }
  .jely{
    top:53.7vh; left:14vw; height:300px
  }

  .scroll-btn {
  top:92vh;
  left:48.6vw;scale:0.9;
  }
  .credi{
    font-size:50px;
    top:1470vh;
    left:28vw;
  }


  .war{
    top:1091vh;scale:0.65
   }
}
















@media screen and (min-height: 750px) and (max-height: 800px) {  
  .vot{
    height:140vh;
     
     }
  .sigma{
    top:55vh;
  }
  .Fem{
    top:150vh;
  }
  .Me{
    top:150vh
  }
  .dayatona{
    top:178vh;scale:1
  }
  .jely{
    top:49.7vh;height:300px
  }


  .war{
    top:1111vh;scale:0.65
   }


   .words33{
    top:243vh; 
  }
  
    .stz{
      top:235vh; 
    }
}

@media screen and (min-height: 700px) and (max-height: 750px) {  
  .sigma{
    top:58vh;
  }
  .Fem{
    top:148vh;
  }
  .Me{
    top:147vh;
  }
  .dayatona{
    top:178vh;
  }
  .jely{
    top:49.7vh;height:300px
  }


  .war{
    top:1091vh;scale:0.8
   }


   .words33{
    top:243vh; 
  }
  
    .stz{
      top:235vh; 
    }
    .scroll-btn {
      top:100vh;
    scale:0.85;
      }
      .Finish{
        top:1209vh;
       }
       .rainbow {
        top:1224vh;
      }

      .Emp{
        top:724vh;
       }  .swy{
        top:719vh;
      }

      
  .vot{
 height:180vh;
  
  }

}






@media screen and (min-width: 1000px) and (max-width: 1600px) and (min-height:700px) and (max-height:900px) {  
  .fz1{
    height:100vh;
    width:8vw;
    background:rgb(3, 0, 10);
    position:fixed;
    left:0vw;
    top:0vh;  z-index:34;
    
    }
    .fz2{
      height:100vh;
      width:10vw;
      background:rgb(3, 0, 10);
      position:fixed;
    left:8vw;
    top:0vh;
    z-index:34;
    }
      .fz3{
    
        height:100vh;
        width:8vw;
        background:rgb(3, 0, 10);
        position:fixed;
        left:16vw;
        top:0vh;
        z-index:34    }
        .fz4{
    
          height:100vh;
          width:8vw;
          background:rgb(3, 0, 10);
          position:fixed;
          left:24vw;
          top:0vh;
          z-index:34      }
          .fz5{
    
            height:100vh;
            width:8vw;
            background:rgb(3, 0, 10);
            position:fixed;
            left:32vw;
            top:0vh;
            z-index:34        }
            .fz6{
    
              height:100vh;
              width:8vw;
              background:rgb(3, 0, 10);
              position:fixed;
              left:40vw;
              top:0vh;
              z-index:34          }
      
    
              .fz7{
    
                height:100vh;
                width:8vw;
                background:rgb(3, 0, 10);
                position:fixed;
                left:48vw;
                top:0vh;
                z-index:34         
                 }
    
                 
    
            .fz11{
    
              height:100vh;
              width:18vw;
              background:rgb(3, 0, 10);
              position:fixed;
              right:225vw;
              top:0vh; z-index:34
              }
              .fz22{
    
                height:100vh;
                width:20vw;
                background:rgb(3, 0, 10);
                position:fixed;
                right:208vw;
              top:0vh;
              z-index:34            }
                .fz33{
    
                  height:100vh;
                  width:38vw;
                  background:rgb(3, 0, 10);
                  position:fixed;
                  right:230vw;
                  top:0vh;
                  z-index:34              }
                  .fz44{
    
                    height:100vh;
                    width:28vw;
                    background:rgb(3, 0, 10);
                    position:fixed;
                    right:200vw;
                    top:0vh;
                    z-index:34                }
                    .fz55{
    
                      height:100vh;
                      width:38vw;
                      background:rgb(3, 0, 10);
                      position:fixed;
                      right:192vw;
                      top:0vh;
                      z-index:34                  }
                      .fz66{
                        height:100vh;
                        width:48vw;
                        background:rgb(3, 0, 10);
                        position:fixed;
                        right:180vw;
                        top:0vh;
                        z-index:34                    
                      }
                      .h{
                        top:35vh;right:212.3vw;font-size:45px;
                     }
                    
                     .ha{
                      top:44.7vh; right:212.3vw;font-size:45px;
                    }
                    .hac{
                      top:54vh;right:211.8vw;font-size:45px;
                    }
    
    
                        .ZO{
                          top:37vh; left:43vw;z-index:35;
                        }
}









@media screen and (min-width: 1550px) and (max-width: 1600px) {  

   .Finish{
    right:-7vw;
   }
  
}
@media screen and (min-height: 950px) and (max-height: 1050px) {
  .vot{
    height:170vh;
     
     }

}






@media screen and (min-height: 600px) and (max-height: 650px) {
  .vot{
    height:170vh;
     
     }

}















@media screen and (min-width:350px)  and (max-width:700px){  

  .fz1{
    height:100vh;
    width:18vw;
    background:rgb(3, 0, 10);
    position:fixed;
    left:-10vw;
    top:0vh;  z-index:34;
    
    }
    .fz2{
      height:100vh;
      width:15vw;
      background:rgb(3, 0, 10);
      position:fixed;
    left:0vw;
    top:0vh;
    z-index:34;
    }
      .fz3{
    
        height:100vh;
        width:18vw;
        background:rgb(3, 0, 10);
        position:fixed;
        left:10vw;
        top:0vh;
        z-index:34    }
        .fz4{
    
          height:100vh;
          width:28vw;
          background:rgb(3, 0, 10);
          position:fixed;
          left:24vw;
          top:0vh;
          z-index:34      }
          .fz5{
    
            height:100vh;
            width:38vw;
            background:rgb(3, 0, 10);
            position:fixed;
            left:68vw;
            top:0vh;
            z-index:34        }
            .fz6{
    
              height:100vh;
              width:38vw;
              background:rgb(3, 0, 10);
              position:fixed;
              left:85vw;
              top:0vh;
              z-index:34          }
      
    
              .fz7{
    
                height:100vh;
                width:38vw;
                background:rgb(3, 0, 10);
                position:fixed;
                left:43vw;
                top:0vh;
                z-index:34         
                 }
    
                 
    
            .fz11{
    
              height:100vh;
              width:8vw;
              background:rgb(3, 0, 10);
              position:fixed;
              right:5vw;
              top:0vh; z-index:34
              }
              .fz22{
    
                height:100vh;
                width:10vw;
                background:rgb(3, 0, 10);
                position:fixed;
                right:13vw;
              top:0vh;
              z-index:34            }
                .fz33{
    
                  height:100vh;
                  width:8vw;
                  background:rgb(3, 0, 10);
                  position:fixed;
                  right:21vw;
                  top:0vh;
                  z-index:34              }
                  .fz44{
    
                    height:100vh;
                    width:8vw;
                    background:rgb(3, 0, 10);
                    position:fixed;
                    right:30vw;
                    top:0vh;
                    z-index:34                }
                    .fz55{
    
                      height:100vh;
                      width:8vw;
                      background:rgb(3, 0, 10);
                      position:fixed;
                      right:36vw;
                      top:0vh;
                      z-index:34                  }
                      .fz66{
                        height:100vh;
                        width:8vw;
                        background:rgb(3, 0, 10);
                        position:fixed;
                        right:45vw;
                        top:0vh;
                        z-index:34                    
                      }
    
    
                        .ZO{
                          top:37vh; left:35vw;z-index:35;
                        }
}  
@media screen and (min-width:350px)  and (max-width:550px) and (min-height:700px) and (max-height:900px){  
  .Fem{
    top:152vh;left:-27.8vw
  }
  .Me{
    top:152.4vh;left:-63vw;
  }
  
}
@media screen and (min-width:350px)  and (max-width:550px){  
  .ZO{
 left:18vw;z-index:35;
  }

  .Emp{
    top:734vh;left:5vw
   }  
   .Finish{
    top:1140vh;
   }
   .rainbow {
    top:1146vh;
  }
  
  
   .Finishh{
    top:1165vh;
  }
   .swy{
    top:742vh;left:6vw;
  }
}



@media screen and (min-width:700px)  and (max-width:1000px){  

  .fz1{
    height:100vh;
    width:18vw;
    background:rgb(3, 0, 10);
    position:fixed;
    left:-10vw;
    top:0vh;  z-index:34;
    
    }
    .fz2{
      height:100vh;
      width:15vw;
      background:rgb(3, 0, 10);
      position:fixed;
    left:0vw;
    top:0vh;
    z-index:34;
    }
      .fz3{
    
        height:100vh;
        width:18vw;
        background:rgb(3, 0, 10);
        position:fixed;
        left:10vw;
        top:0vh;
        z-index:34    }
        .fz4{
    
          height:100vh;
          width:28vw;
          background:rgb(3, 0, 10);
          position:fixed;
          left:24vw;
          top:0vh;
          z-index:34      }
          .fz5{
    
            height:100vh;
            width:38vw;
            background:rgb(3, 0, 10);
            position:fixed;
            left:68vw;
            top:0vh;
            z-index:34        }
            .fz6{
    
              height:100vh;
              width:38vw;
              background:rgb(3, 0, 10);
              position:fixed;
              left:85vw;
              top:0vh;
              z-index:34          }
      
    
              .fz7{
    
                height:100vh;
                width:38vw;
                background:rgb(3, 0, 10);
                position:fixed;
                left:43vw;
                top:0vh;
                z-index:34         
                 }
    
                 
    
            .fz11{
    
              height:100vh;
              width:8vw;
              background:rgb(3, 0, 10);
              position:fixed;
              right:5vw;
              top:0vh; z-index:34
              }
              .fz22{
    
                height:100vh;
                width:10vw;
                background:rgb(3, 0, 10);
                position:fixed;
                right:13vw;
              top:0vh;
              z-index:34            }
                .fz33{
    
                  height:100vh;
                  width:8vw;
                  background:rgb(3, 0, 10);
                  position:fixed;
                  right:21vw;
                  top:0vh;
                  z-index:34              }
                  .fz44{
    
                    height:100vh;
                    width:8vw;
                    background:rgb(3, 0, 10);
                    position:fixed;
                    right:30vw;
                    top:0vh;
                    z-index:34                }
                    .fz55{
    
                      height:100vh;
                      width:8vw;
                      background:rgb(3, 0, 10);
                      position:fixed;
                      right:36vw;
                      top:0vh;
                      z-index:34                  }
                      .fz66{
                        height:100vh;
                        width:8vw;
                        background:rgb(3, 0, 10);
                        position:fixed;
                        right:45vw;
                        top:0vh;
                        z-index:34                    
                      }
    
    
                        .ZO{
                          top:37vh; left:35vw;z-index:35;
                        }
}






@media screen and (min-width:1000px)  and (max-width:1600px){  

  .fz1{
    height:100vh;
    width:18vw;
    background:rgb(3, 0, 10);
    position:fixed;
    left:-10vw;
    top:0vh;  z-index:34;
    
    }
    .fz2{
      height:100vh;
      width:15vw;
      background:rgb(3, 0, 10);
      position:fixed;
    left:0vw;
    top:0vh;
    z-index:34;
    }
      .fz3{
    
        height:100vh;
        width:18vw;
        background:rgb(3, 0, 10);
        position:fixed;
        left:10vw;
        top:0vh;
        z-index:34    }
        .fz4{
    
          height:100vh;
          width:28vw;
          background:rgb(3, 0, 10);
          position:fixed;
          left:24vw;
          top:0vh;
          z-index:34      }
          .fz5{
    
            height:100vh;
            width:38vw;
            background:rgb(3, 0, 10);
            position:fixed;
            left:68vw;
            top:0vh;
            z-index:34        }
            .fz6{
    
              height:100vh;
              width:38vw;
              background:rgb(3, 0, 10);
              position:fixed;
              left:85vw;
              top:0vh;
              z-index:34          }
      
    
              .fz7{
    
                height:100vh;
                width:38vw;
                background:rgb(3, 0, 10);
                position:fixed;
                left:43vw;
                top:0vh;
                z-index:34         
                 }
    
                 
    
            .fz11{
    
              height:100vh;
              width:8vw;
              background:rgb(3, 0, 10);
              position:fixed;
              right:5vw;
              top:0vh; z-index:34
              }
              .fz22{
    
                height:100vh;
                width:10vw;
                background:rgb(3, 0, 10);
                position:fixed;
                right:13vw;
              top:0vh;
              z-index:34            }
                .fz33{
    
                  height:100vh;
                  width:8vw;
                  background:rgb(3, 0, 10);
                  position:fixed;
                  right:21vw;
                  top:0vh;
                  z-index:34              }
                  .fz44{
    
                    height:100vh;
                    width:8vw;
                    background:rgb(3, 0, 10);
                    position:fixed;
                    right:30vw;
                    top:0vh;
                    z-index:34                }
                    .fz55{
    
                      height:100vh;
                      width:8vw;
                      background:rgb(3, 0, 10);
                      position:fixed;
                      right:36vw;
                      top:0vh;
                      z-index:34                  }
                      .fz66{
                        height:100vh;
                        width:8vw;
                        background:rgb(3, 0, 10);
                        position:fixed;
                        right:45vw;
                        top:0vh;
                        z-index:34                    
                      }
    
    
                        .ZO{
                          top:37vh; left:35vw;z-index:35;
                        }
}




@media screen and (min-height: 700px) and (max-height: 850px) and (min-width: 900px) and (max-width: 1000px) {  
  .Rss{
    right:-15.7vw
      }
  .Fem{
    top:140vh;left:6.8vw
  }
  .Me{
    top:139.4vh;left:-10vw;
  }
  .dayatona{
    top:174vh;right:-34vw;height:600px; width:600px;scale:0.83
  }
  .words33{
    top:218vh; 
  }
  
    .stz{
      top:210vh; 
    }
    .war{
      top:1133vh;right:-210vw;scale:0.8
    }
    
  .sigma{
  left:32vw;height:170px;
  }
}

@media screen and (min-height: 700px) and (max-height: 850px) and (min-width: 950px) and (max-width: 1000px) {  
  .sigma{
    left:36vw;height:170px;
    }
}

@media screen and (min-height: 700px) and (max-height: 850px) and (min-width: 400px) and (max-width: 800px) {  
  .dayatona{
  scale:0.78
  }
}

@media screen and (min-height: 700px) and (max-height: 850px) and (min-width: 600px) and (max-width: 800px) {  
  .dayatona{
  scale:0.82
  }
  .Fem{
    top:147vh;left:.8vw
  }
  .Me{
    top:149.4vh;left:-21vw;
  }
}







@media screen and (min-height: 700px) and (max-height: 850px) and (min-width: 800px) and (max-width: 950px) {  
  .Rss{
    right:-20.7vw
      }
  .Fem{
    top:150vh;left:6.8vw
  }
  .Me{
    top:152.4vh;left:-10vw;
  }
  .dayatona{
    top:174vh;right:-34vw;height:600px; width:600px;scale:0.83
  }
  .words33{
    top:233vh; 
  }
  
    .stz{
      top:225vh; 
    }
    .war{
      top:1110vh;right:-220vw;scale:0.73
    }
    
  .sigma{
  left:32vw;height:180px;
  }
  .meat{
    top:8vh;
    left:-3vw;
}
}

@media screen and (min-height: 700px) and (max-height: 850px) and (min-width: 800px) and (max-width: 850px) {  
  .war{
    top:1115vh;right:-230vw;scale:0.73
  }
  .sigma{
  left:24vw;height:180px;
  }
}

@media screen and (min-height: 700px) and (max-height: 850px) and (min-width: 900px) and (max-width: 950px) {  
  .war{
    top:1115vh;right:-195vw;scale:0.73
  }
  .Me{
    top:140.4vh;left:-10vw;
  }
  .Fem{
    top:140vh;left:6.8vw
  }
  .Rss{
    right:-14.7vw
      }
  .sigma{
  left:28vw;height:180px;
  }
  .words33{
    top:223vh; 
  }
  
    .stz{
      top:215vh; 
    }
}

@media screen and (min-height: 700px) and (max-height: 850px) and (min-width: 950px) and (max-width: 1150px) {  

  .war{
    top:1110vh;right:-180vw;scale:0.73
  }
}

@media screen and (min-height: 650px) and (max-height: 900px) and (min-width: 650px) and (max-width: 850px) {  
  .war{
    top:1120vh;scale:0.73
  }
}

@media screen and (min-height: 750px) and (max-height: 850px) and (min-width: 950px) and (max-width: 1350px) {  
  .dayatona{
    top:174vh;right:-34vw;height:600px; width:600px;scale:0.83
  }
  .Me{
    top:143.4vh;left:-10vw;
  }
  .Fem{
    top:144vh;left:6.8vw
  }
  .war{
    top:1110vh;scale:0.73
  }
}

@media screen and (min-height: 700px) and (max-height: 850px) and (min-width: 700px) and (max-width: 800px) {  
  .dayatona{
    top:174vh;right:-37vw;height:600px; width:600px;scale:0.82
  }
  .war{
    top:1093vh;
  }
  
}

@media screen and (min-height:840px)  and (max-height:890px){  
  .sigma{
  height:130px;top:52vh
    }
}

@media screen and (min-height:800px)  and (max-height:900px) and (min-width:300px) and (max-width:550px){  
  .Me{
    top:158vh;left:-45vw;
  }
  .Fem{
    top:158vh;left:-17.8vw
  }
  .words33{
    top:233vh; 
  }
  
    .stz{
      top:226vh; left:-4vw;
    }
    .dayatona{
      top:180vh;right:-44vw;height:550px; width:550px;scale:0.8
    }
    .Finish{
      top:1230vh;left:0vw;
     }
     .rainbow {
      top:1236vh;
    }
    
    
     .Finishh{
      top:1255vh;
    }

}


@media screen and (min-height:300px)  and (max-height:890px){  
.h{
  display:none;opacity:0;top:-10000vh
}
.ha{
  display:none;opacity:0;top:-10000vh
}
.hac{
  display:none;opacity:0;top:-10000vh
}
}



@media screen and (min-height:650px)  and (max-height:700px) and (min-width:1120px) and (max-width:1180px){  
  .Rss{
scale:0.85;top:-2vh
      }
      .Professional{
        top:1vh;
        left:-3vw;
        z-index:2;
        height:220px;
        z-index:25;
      
      }
    
    .Wheel{
      top:5vh;
      left:0vw;
      z-index:0;
      height:152px;
      width:192px;
      z-index:24;scale:0.95
    }
    .fa-brands.fa-instagram {
      left:5vw;font-size:29px;top:50vh
    }
    .fa-brands.fa-x-twitter {
      left:5vw;font-size:29px;    top:61vh;
    }
    
  .stz{
    top:224vh; 
  }
  .sigma{
    top:48vh;scale:0.9;
  }
  .Fem{
    top:153vh;left:-23.8vw
  }
  .Me{
    top:154.4vh;left:-40vw;
  }

  .jely{
    top:39.7vh; left:4vw; height:280px
  }

  .scroll-btn {
  top:77vh;
  left:41.6vw;scale:0.8;
  }
  .credi{
    font-size:50px;
    top:1470vh;
    left:28vw;
  }


  .war{
    top:1091vh;scale:0.65
   }
   .meat{
    top:4vh;
    left:-0vw;
    scale:1
}





.Fem{
  top:143vh;left:-1.8vw
}
.Me{
  top:143.4vh;left:-14vw;
}

.at{
  top:171vh; left:10vw;font-size:70px
}




.line{
top:199vh;right:39.4vw;border-radius:20%;height:22px;width:22px;background:linear-gradient(black,black);border:solid 0.1px rgb(97, 2, 37);
}



.linee{
top:194vh;right:40.5vw;height:300px;width:1px;background:linear-gradient(rgb(60, 0, 109),black);border-radius:50px;
}


.words33{
top:235vh; left:13vw;
}

.stz{
  top:226vh; left:7.5vw;
}
.dayatona{
  top:183vh;right:-30vw;height:600px; width:600px;scale:0.8
}
.wordst{
  top:192.6vh; left:10.5vw;font-size:32px;width:500px
}
.Emp{
  top:730vh;left:5vw
 }     .swy{
  top:720vh;left:6vw;
}
.swyy{
  top:754vh;left:7vw;font-size:55px; 
}
.Finish{
  top:1200vh;left:10vw;
 }
 .rainbow {
  top:1216vh;
}


 .Finishh{
  top:1250vh;
}
.war{
  top:1070vh;scale:0.8;
}
  }













  
@media screen and (min-height:650px)  and (max-height:700px){  
  .Rss{
scale:0.7;top:4vh
      }
      .Professional{
        top:1vh;
        left:9vw;
        z-index:2;
        height:220px;
        z-index:25;
      
      }
    
    .Wheel{
      top:6vh;
      left:12vw;
      z-index:0;
      height:152px;
      width:192px;
      z-index:24;scale:0.95
    }
    .fa-brands.fa-instagram {
      left:15vw;font-size:31px;top:50vh
    }
    .fa-brands.fa-x-twitter {
      left:15vw;font-size:31px;    top:61vh;
    }
    
  .stz{
    top:224vh; 
  }
  .sigma{
    top:32vh;height:130px;
  }
  .Fem{
    top:153vh;left:-23.8vw
  }
  .Me{
    top:154.4vh;left:-40vw;
  }

  .jely{
    display:none;
  }

  .scroll-btn {
  top:77vh;
  left:41.6vw;scale:0.8;
  }
  .credi{
display:none;opacity:0
  }


  .war{
    top:1091vh;scale:0.65
   }
   .meat{
    top:-4vh;
    scale:1
}





.Fem{
  top:143vh;left:-1.8vw
}
.Me{
  top:143.4vh;left:-14vw;
}

.at{
  top:171vh; left:10vw;font-size:70px
}




.line{
top:199vh;right:39.4vw;border-radius:20%;height:22px;width:22px;background:linear-gradient(black,black);border:solid 0.1px rgb(97, 2, 37);
}



.linee{
top:194vh;right:40.5vw;height:300px;width:1px;background:linear-gradient(rgb(60, 0, 109),black);border-radius:50px;
}


.words33{
top:235vh; left:13vw;
}

.stz{
  top:226vh; left:7.5vw;
}
.dayatona{
 scale:0.9
}
.wordst{
  top:192.6vh; left:10.5vw;font-size:32px;width:500px
}
.Emp{
  top:730vh;left:5vw
 }     .swy{
  top:720vh;left:6vw;
}
.swyy{
  top:754vh;left:7vw;font-size:55px; 
}
.Finish{
  top:1200vh;left:10vw;
 }
 .rainbow {
  top:1216vh;
}


 .Finishh{
  top:1250vh;
}
.war{
  top:1070vh;scale:0.8;
}
  }



  @media screen and (min-height:650px)  and (max-height:700px) and (min-width:480px)  and (max-width:550px){   
.meat{
  scale:0.7;        top:1vh;
  
}
.Professional{
  top:1vh;
  left:-8vw;
  z-index:2;
  height:220px;
  z-index:25;

}
.fa-brands.fa-instagram {
  left:10vw;font-size:29px;top:50vh
}
.fa-brands.fa-x-twitter {
  left:10vw;font-size:29px;    top:61vh;
}

.Wheel{
top:6vh;
left:-5vw;
z-index:0;
height:152px;
width:192px;
z-index:24;scale:0.95
}
  }
  @media screen and (min-height:650px)  and (max-height:700px) and (min-width:550px)  and (max-width:1200px){   
    .sigma{
      scale:1.1;
       }
       .meat{
        top:4vh;
        scale:0.8
    }
  }

  @media screen and (min-height:650px)  and (max-height:700px) and (min-width:650px)  and (max-width:700px){   
    .sigma{
   scale:1.1;
    }
    .dayatona{
      scale:0.83;right:-35vw
     }
     .words33{
      top:235vh; left:13vw;
      }
      .wordst{
        top:192.6vh; left:5.5vw;font-size:30px;width:400px
      }
      .stz{
        top:226vh; left:.5vw;
      }
      
.Fem{
  top:143vh;left:-10.8vw
}
.Me{
  top:143.4vh;left:-35vw;
}

.at{
  top:167vh; left:5vw;font-size:70px
}
.words33{
  top:235vh; left:10vw;width:200px
  }
  


  }



  @media screen and (min-height:650px)  and (max-height:700px) and (min-width:750px)  and (max-width:1100px){   
  }

    @media screen and (min-height:650px)  and (max-height:700px) and (min-width:350px)  and (max-width:480px){   
      
.line{
  top:199vh;right:33.4vw;border-radius:20%;height:19px;width:19px;background:linear-gradient(black,black);border:solid 0.1px rgb(97, 2, 37);
  }
  
  .at{
    top:167vh; left:0vw;font-size:70px
  }
  
  .linee{
  top:194vh;right:36.5vw;height:300px;width:1px;background:linear-gradient(rgb(60, 0, 109),black);border-radius:50px;
  }
      .dayatona{
        scale:0.7;right:-44vw
       }
      .wordst{
        top:192.6vh; left:.5vw;font-size:28px;width:300px
      }
      .stz{
        top:235vh; left:-5vw;
      }
      .words33{
        top:244vh; left:12vw;width:150px
        }
        
      .Fem{
        top:142vh;left:-26.8vw
      }
      .Me{
        top:142.4vh;left:-58vw;
      }

      .Rss{
        scale:0.55;top:-2vh
              }
              .Professional{
                top:-5vh;
                left:-4vw;
                z-index:2;
                height:170px;
                z-index:25;
              
              }
            
            .Wheel{
              top:-3vh;
              left:-2vw;
              z-index:0;
              height:142px;
              width:182px;
              z-index:24;scale:0.92
            }
            .fa-brands.fa-instagram {
              left:75vw;font-size:26px;top:5vh
            }
            .fa-brands.fa-x-twitter {
              left:75vw;font-size:26px;    top:13vh;
            }
            
          
          .meat{
            top:1vh;
            left:-32vw;
            scale:0.85
          }
          .words33{
            top:244vh; left:13vw;width:150px
            }
    }










    @media screen and (min-height:650px)  and (max-height:700px) and (min-width:480px)  and (max-width:550px){   
      
      .line{
        top:199vh;right:33.4vw;border-radius:20%;height:19px;width:19px;background:linear-gradient(black,black);border:solid 0.1px rgb(97, 2, 37);
        }
        
        .at{
          top:167vh; left:0vw;font-size:70px
        }
        
        .linee{
        top:194vh;right:36.5vw;height:300px;width:1px;background:linear-gradient(rgb(60, 0, 109),black);border-radius:50px;
        }
            .dayatona{
              scale:0.7;right:-44vw
             }
            .wordst{
              top:192.6vh; left:.5vw;font-size:28px;width:300px
            }
            .stz{
              top:235vh; left:-3vw;
            }
            .words33{
              top:244vh; left:6vw;width:150px
              }
              
            .Fem{
              top:142vh;left:-26.8vw
            }
            .Me{
              top:142.4vh;left:-58vw;
            }
      
            .Rss{
              scale:0.6;top:-2vh; left:-67vw;
                    }
                    .Professional{
                      top:-5vh;
                      left:-4vw;
                      z-index:2;
                      height:170px;
                      z-index:25;
                    
                    }
                  
                  .Wheel{
                    top:-3vh;
                    left:-2vw;
                    z-index:0;
                    height:142px;
                    width:182px;
                    z-index:24;scale:0.92
                  }
                  .fa-brands.fa-instagram {
                    left:75vw;font-size:26px;top:5vh
                  }
                  .fa-brands.fa-x-twitter {
                    left:75vw;font-size:26px;    top:13vh;
                  }
                  
                
                .meat{
                  top:4vh;
                  left:-32vw;
                  scale:0.85
                }
                .words33{
                  top:244vh; left:13vw;width:150px
                  }
         
          }
      

















    @media screen and (min-height:650px)  and (max-height:700px) and (min-width:400px)  and (max-width:550px){   
      .words33{
        top:244vh; left:9vw;width:150px
        }
        .Rss{
          scale:0.55;top:-2vh
                }
    }
  @media screen and (min-height:650px)  and (max-height:700px) and (min-width:550px)  and (max-width:750px){   


  .Professional{
    top:0vh;
    left:-3vw;
    z-index:2;
    height:220px;
    z-index:25;
  
  }

.Wheel{
  top:6vh;
  left:1vw;
  z-index:0;
  height:152px;
  width:192px;
  z-index:24;scale:0.95
}
.meat{
  top:-11vh;
  scale:0.9
}
.fa-brands.fa-instagram {
  left:7vw;font-size:31px;top:35vh
}
.fa-brands.fa-x-twitter {
  left:7vw;font-size:31px;    top:47vh;
}
  }




  @media screen and (min-height:850px)  and (max-height:1000px) and (min-width:350px)  and (max-width:470px){   
    .war{
      display:none;
     }
    .Finish{
      top:1204vh;right:-255vw;font-size: 40px;
     }
     .Finishh{
      top:1226vh;left:3.2vw;font-size: 32px;
     }
     .rainbow {
      top:1210vh;left:0vw;font-size: 70px;
    }
    .line{
      top:199vh;right:33.4vw;border-radius:20%;height:19px;width:19px;background:linear-gradient(black,black);border:solid 0.1px rgb(97, 2, 37);
      }
            .words33{
        top:233vh; left:6.5vw;width:150px
        }
      .at{
        top:173vh; left:0vw;font-size:70px
      }
      
      .linee{
      top:194vh;right:36.5vw;height:300px;width:1px;background:linear-gradient(rgb(60, 0, 109),black);border-radius:50px;
      }
          .dayatona{
            scale:0.7;right:-51vw;top:184vh;
           }
          .wordst{
            top:192.6vh; left:1vw;font-size:30px;width:250px
          }
          .stz{
             left:-9vw ; top:226vh !important; 
          }
          .Fem{
            top:151vh;left:-23.8vw
          }
          .Me{
            top:151.4vh;left:-60vw;
          }
  }
  @media screen and (min-height:650px)  and (max-height:900px) and (min-width:350px)  and (max-width:450px){   
    .war{
      display:none;
     }
    .Finish{
      top:1144vh;right:-330vw;font-size: 40px;
     }
     .Finishh{
      top:1166vh;left:3.2vw;font-size: 32px;
     }
     .rainbow {
      top:1150vh;left:0vw;font-size: 70px;
    }
  }
  @media screen and (min-height:830px)  and (max-height:900px) {   
.sigma{
  height:160px;
}
  }

  @media screen and (min-width:900px ) and (min-width:1200px  ) and (min-height:830px)  and (max-height:900px){   
    .sigma{
      height:220px;
    }
      }


      @media screen and (min-width:300px ) and (max-width:400px  ) and (min-height:800px)  and (max-height:900px){   
        .line{
          top:199vh;right:33.4vw;border-radius:20%;height:19px;width:19px;background:linear-gradient(black,black);border:solid 0.1px rgb(97, 2, 37);
          }
          
          .at{
            top:173vh; left:0vw;font-size:70px
          }
          
          .linee{
          top:194vh;right:36.5vw;height:300px;width:1px;background:linear-gradient(rgb(60, 0, 109),black);border-radius:50px;
          }
              .dayatona{
                scale:0.7;right:-44vw
               }
              .wordst{
                top:192.6vh; left:2vw;font-size:26px;width:250px
              }
              .stz{
                 left:-9vw ; top:226vh !important; 
              }
              .Fem{
                top:151vh;left:-23.8vw
              }
              .Me{
                top:151.4vh;left:-60vw;
              }
        

          }

          @media screen and (min-height:600px)  and (max-height:700px) and (min-width:550px)  and (max-width:650px){   
            .Professional{
              top:0vh;
              left:-6vw;
              z-index:2;
              height:220px;
              z-index:25;
            
            }
          
          .Wheel{
            top:6vh;
            left:-2vw;
            z-index:0;
            height:152px;
            width:192px;
            z-index:24;scale:0.95
          }
          .sigma{
            height:170px;top:65vh
          }
          .meat{
            top:11vh;
            scale:0.9
          }
          .line{
            top:199vh;right:43vw;border-radius:20%;height:19px;width:19px;background:linear-gradient(black,black);border:solid 0.1px rgb(97, 2, 37);
            }

            .at{
              top:170vh; left:0vw;font-size:70px
            }
            
            .linee{
            top:194vh;right:44.5vw;height:300px;width:1px;background:linear-gradient(rgb(60, 0, 109),black);border-radius:50px;
            }
            .words33{
              top:248vh; left:6.5vw;width:150px
              }
                .dayatona{
                  scale:0.8;right:-36vw
                 }
                .wordst{
                  top:194.6vh; left:2vw;font-size:26px;width:250px
                }
                .stz{
                   left:-5.5vw ; top:238vh ; 
                }
                .Fem{
                  top:141vh;left:-23.8vw
                }
                .Me{
                  top:141vh;left:-50vw;
                }

                .credi{
                  font-size:50px;
                  top:1470vh;
                  left:28vw;
                }
              
              
                .war{
                  top:1061vh;scale:0.7
                 }
                .Finish{
                  top:1222vh;right:-174vw;font-size: 40px;
                 }
                 .Finishh{
                  top:1250vh;left:3.2vw;font-size: 32px;
                 }
                 .rainbow {
                  top:1230vh;left:0vw;font-size: 70px;
                }
          }

          @media screen and (min-height:600px)  and (max-height:700px) and (min-width:350px)  and (max-width:700px){   
            .credi{
              font-size:50px;
              top:1420vh;
              left:28vw;
            }
            .war{
              top:1001vh;scale:0.7
             }
            .Finish{
              top:1162vh;right:-214vw;font-size: 40px;
             }
             .Finishh{
              top:1190vh;left:3.2vw;font-size: 32px;
             }
             .rainbow {
              top:1170vh;left:0vw;font-size: 70px;
            }
            .name{
              top:1207vh;left:2.1vw;font-size:30px;width:150px
            }
            .namee{
              top:1217vh;left:2.1vw;width:260px; font-size:35px
            }
            .button-85 {
              height:55px;
              width:230px;
              top:1235vh;
              left:3vw;
            }
            
          }
 
          @media screen and (min-height:600px)  and (max-height:700px) and (min-width:550px)  and (max-width:700px){   
            .Finishh{
              top:1195vh;left:1.2vw;font-size: 32px;
             }
             .Finish{
              top:1157vh;right:-124vw;font-size: 50px;
             }
          }
          @media screen and (min-height:700px)  and (max-height:850px) and (min-width:550px)  and (max-width:750px){   
.Finish{
  top:1215vh;
}
            .Fem{
              top:155vh;left:-14.8vw
            }
            .Me{
              top:155.4vh;left:-35vw;
            }
          }

          @media screen and (min-height:600px)  and (max-height:700px) and (min-width:500px)  and (max-width:550px){   
            .Finish{
              top:1157vh;right:-124vw;font-size: 50px;
             }
          }

          @media screen and (min-height:600px)  and (max-height:700px) and (min-width:350px)  and (max-width:550px){   
            .sigma{
              height:150px;top:65vh
            }
          }

          @media screen and (min-height:600px)  and (max-height:700px) and (min-width:700px)  and (max-width:1550px){   
            .sigma{
              height:160px;top:65vh
            }
            .scroll-btn {
              top:92vh;
              left:48.6vw;scale:0.9;
              }
              .words33{
                top:241vh; 
                }
                .Fem{
                  top:142vh;left:.8vw
                }
                .Me{
                  top:142.4vh;left:-15vw;
                }
                .at{
                  top:170vh; left:20vw;font-size:65px
                }
          }

          @media screen and (min-height:600px)  and (max-height:700px) and (min-width:700px)  and (max-width:1550px){   
            .Fem{
              top:142vh;left:.8vw
            }
            .Me{
              top:142.4vh;left:-15vw;
            }
            .at{
              top:170vh; left:20vw;font-size:65px
            }
          }

          @media screen and (min-height:600px)  and (max-height:700px) and (min-width:700px)  and (max-width:1550px){   
            .Fem{
              top:142vh;left:.8vw
            }
            .Me{
              top:142.4vh;left:-15vw;
            }
            .at{
              top:170vh; left:20vw;font-size:65px
            }
          }

          @media screen and (min-height:600px)  and (max-height:650px) and (min-width:600px)  and (max-width:1050px) {  
            .dayatona{
              scale:0.9
             }
          }

          @media screen and (min-height:650px)  and (max-height:700px) and (min-width:600px)  and (max-width:1050px) {  
            .sigma{
           left:38vw
            }
          }
          @media screen and (min-height:600px)  and (max-height:650px) and (min-width:300px)  and (max-width:450px){  
            .at{
              top:170vh; left:5vw;font-size:65px
            }
            .Fem{
              top:144vh;left:-28.8vw
            }
            .Me{
              top:144vh;left:-65vw;
            }

            

          @media screen and (min-height:650px)  and (max-height:700px){  
            .sigma{
              height:130px;top:48vh;left:28vw
            }
          }
          @media screen and (min-height:600px)  and (max-height:650px) and (min-width:650px)  and (max-width:700px){  
            .at{
              top:170vh; left:5vw;font-size:65px
            }
            .Fem{
              top:144vh;left:-28.8vw
            }
            .Me{
              top:144vh;left:-65vw;
            }
          }
          .dayatona{
            scale:1;right:-36vw
           }
          }

          @media screen and (min-height:600px)  and (max-height:650px) and (min-width:650px)  and (max-width:700px){  
            .at{
              top:170vh; left:5vw;font-size:65px
            }
            .Fem{
              top:144vh;left:-15.8vw
            }
            .Me{
              top:147vh;left:-35vw;
            }
   
                    .words33{
                top:234vh; left:6.5vw;width:150px
                }
              .at{
                top:173vh; left:4vw;font-size:70px
              }        .dayatona{
                scale:0.9;right:-44vw;top:184vh;
               }
              .wordst{
                top:193vh; left:4vw;font-size:28px;width:350px
              }
              .stz{
                left:-3vw ; top:224vh ; 
             }
             .war{
              top:1021vh;scale:0.7
             }
            .Finish{
              top:1162vh;right:-130vw;font-size: 40px;
             }
             .Finishh{
              top:1190vh;left:3.2vw;font-size: 32px;
             }
          }
    
          @media screen and (min-height:600px)  and (max-height:650px) and (min-width:500px)  and (max-width:550px){  
                 .dayatona{
            scale:0.9;right:-34vw;top:184vh;
           }
            .at{
              top:173vh; left:4vw;font-size:70px
            }   
            .Fem{
              top:144vh;left:-6.8vw
            }
            .Me{
              top:144vh;left:-35vw;
            }
            .stz{
              left:-5vw ; top:238vh ; 
           }
           .words33{
            top:248vh; left:7.5vw;width:150px
            }
          }

          @media screen and (min-height:650px)  and (max-height:750px) and (min-width:600px)  and (max-width:750px){  
            .at{
              top:170vh;
            }   
            .Fem{
              top:144vh;left:-8.8vw
            }
            .Me{
              top:144vh;left:-25vw;
            }
          }

          @media screen and (min-width:450px)  and (max-width:1150px) and (min-height:650px)  and (max-height:700px) {
            .Rss{
              scale:0.6;top:-2vh; 
                    }
                    .sigma{
                      height:130px;top:48vh;
                    }

          }
          @media screen and (min-width:1000px)  and (max-width:1100px) and (min-height:700px)  and (max-height:750px) {

            .Fem{
              top:142vh;left:6.8vw
            }
            .Me{
              top:144vh;left:-5vw;
            }
          }